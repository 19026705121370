import { useAuth0 } from '@auth0/auth0-react';

import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { Step, Stepper } from 'src/components/Stepper';
import { enterpriseHelpers } from 'src/utils/enterpriseHelpers';

export function EnterpriseSteps() {
  const auth = useAuth0();
  const enrollmentStatus = useEnterpriseEnrollmentStatus();

  const currentStep = enterpriseHelpers.getEnrollmentStep(
    enrollmentStatus.data,
    auth.user
  );

  return (
    <Stepper>
      {enterpriseHelpers.getVisibleEnrollmentSteps().map((step) => (
        <Step
          key={step}
          checked={step < currentStep}
          disabled={step > currentStep}
          to={enterpriseHelpers.getEnrollmentStepUrl(step)}
        >
          {enterpriseHelpers.getEnrollmentStepName(step)}
        </Step>
      ))}
    </Stepper>
  );
}
