import { Typography } from '@remarkable/ark-web';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { useCart } from 'src/api/queries';
import { Feedback, SomethingWentWrong, Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { stripePromise } from 'src/stripePromise';

import { CheckoutForm } from '../components/CheckoutForm';

export const ConnectCheckoutPage = () => {
  const cart = useCart();

  if (cart.setup.isPending) return <Spinner />;
  if (cart.setup.isError) return <SomethingWentWrong />;

  const options: StripeElementsOptions = {
    clientSecret: cart.setup.data?.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <AppPage.Container>
        <div className="m-auto my-32 max-w-lm px-16 text-center lm:mt-56 ll:mb-48 ll:mt-64">
          <Typography variant="responsive-heading-lg">
            Subscribe to Connect
          </Typography>

          <Typography variant="responsive-body-md" className="my-0">
            You&apos;re one step away from starting your subscription.
          </Typography>
        </div>

        <AppPage.Content className="max-w-ll">
          <div className="mb-64 mt-16">
            <CheckoutForm cart={cart} />
          </div>

          <Feedback
            className="mb-32"
            title="Did you find what you were looking for?"
            feedbackTargetId="store-connect-checkout"
          />
        </AppPage.Content>
      </AppPage.Container>
    </Elements>
  );
};
