import { InputHTMLAttributes } from 'react';

import { RadioButton as ArkRadioButton } from '@remarkable/ark-web';

import { cn } from 'src/utils/classNamesHelper';

export function RadioButton({
  checked,
  className,
  children,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label
      className={cn(
        'flex items-start gap-24 rounded border p-24 focus-within:border-pen-blue-light',
        checked && 'bg-neutral-light-2',
        className
      )}
    >
      <ArkRadioButton
        checked={checked}
        className="mt-8"
        {...props}
      ></ArkRadioButton>
      <div>{children}</div>
    </label>
  );
}
