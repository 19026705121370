import { useIsMutating } from '@tanstack/react-query';

import { queryKeys, useSubscription } from 'src/api/queries';
import { Spinner } from 'src/components';
import { FlexDivider } from 'src/components/FlexDivider';
import { TextWithLabel } from 'src/components/TextWithLabel';

import { useFormattedPaymentInfo } from '../utils/getFormattedPaymentInfo';

export const CreditCardDetails = () => {
  const subscriptions = useSubscription();
  const paymentInfo = useFormattedPaymentInfo(subscriptions.data);
  const isVerifyingPaymentMethod = !!useIsMutating({
    mutationKey: queryKeys.subscriptions.verifySetupIntent,
  });
  const isCard = paymentInfo?.type == 'card';

  const paymentCardText = isCard
    ? paymentInfo.details.cardBrand && paymentInfo.details.lastDigits
      ? `${paymentInfo.details.cardBrand} •••• ${paymentInfo.details.lastDigits}`
      : '---'
    : '';

  const paymentCardExpires = isCard
    ? paymentInfo.details.expireMonth && paymentInfo.details.expireYear
      ? `${paymentInfo.details.expireMonth} / ${paymentInfo.details.expireYear}`
      : '---'
    : '';

  return (
    <div className="flex w-full flex-wrap gap-16 ls:flex-row">
      {isVerifyingPaymentMethod ? (
        <div className="flex h-48 justify-center">
          <Spinner inline />
        </div>
      ) : (
        <>
          <TextWithLabel
            label="Payment card"
            className="flex-1"
            data-cy="payment-card"
          >
            {paymentCardText}
          </TextWithLabel>

          <FlexDivider />

          <TextWithLabel label="Expires" className="flex-1">
            {paymentCardExpires}
          </TextWithLabel>
        </>
      )}
    </div>
  );
};
