import { createFileRoute, Outlet } from '@tanstack/react-router';

import { CookieBanner } from 'src/components';

export const Route = createFileRoute('/_public')({
  component: () => (
    <>
      <Outlet />
      <CookieBanner />
    </>
  ),
});
