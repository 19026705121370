import { cloudEnterpriseAdminApiClient } from '../clients/cloudEnterpriseAdminApiClient';

export const getRoles = () =>
  cloudEnterpriseAdminApiClient
    .GET('/admin/v1/roles')
    .then((r) => r.data ?? null);

export const getRolesByUser = (vars: { memberId: string }) =>
  cloudEnterpriseAdminApiClient
    .GET('/admin/v1/members/{memberId}/roles', {
      params: {
        path: {
          memberId: vars.memberId,
        },
      },
    })
    .then((r) => r.data ?? null);

export const addRoleOnUser = (vars: { memberId: string; roleId: string }) =>
  cloudEnterpriseAdminApiClient.PUT(
    '/admin/v1/members/{memberId}/roles/{roleId}',
    {
      params: {
        path: {
          memberId: vars.memberId,
          roleId: vars.roleId,
        },
      },
    }
  );

export const deleteRoleOnUser = (vars: { memberId: string; roleId: string }) =>
  cloudEnterpriseAdminApiClient.DELETE(
    '/admin/v1/members/{memberId}/roles/{roleId}',
    {
      params: {
        path: {
          memberId: vars.memberId,
          roleId: vars.roleId,
        },
      },
    }
  );

export const getOrgSettings = () => {
  return cloudEnterpriseAdminApiClient
    .GET('/admin/v1/settings')
    .then((res) => res.data ?? null);
};

export const enableEnterpriseMigration = (vars: { domain: string }) =>
  cloudEnterpriseAdminApiClient.POST('/admin/v1/domains/migrate', {
    body: { domain: vars.domain },
  });

export const enableEnterpriseDomainForwarding = (vars: { domain: string }) =>
  cloudEnterpriseAdminApiClient.POST('/admin/v1/domains/forward', {
    body: { domain: vars.domain },
  });
