import { PaypalLogo } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useIsMutating } from '@tanstack/react-query';

import { queryKeys, useSubscription } from 'src/api/queries';
import { Spinner } from 'src/components';
import { FlexDivider } from 'src/components/FlexDivider';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

export const WalletDetails = () => {
  const subscription = useSubscription();
  const isVerifyingPaymentMethod = !!useIsMutating({
    mutationKey: queryKeys.subscriptions.verifySetupIntent,
  });
  const paymentInfo = subscriptionHelpers.isStripe(subscription.data)
    ? subscription.data.paymentInformation?.payment
    : null;

  if (!paymentInfo) {
    return null;
  }
  return (
    <div className="flex w-full flex-wrap gap-16 ls:flex-row">
      {isVerifyingPaymentMethod ? (
        <div className="flex h-48 justify-center">
          <Spinner inline />
        </div>
      ) : (
        <>
          {paymentInfo.type == 'paypal' && (
            <>
              <PaypalLogo alt="paypal" weight="fill" size={36} />
              <FlexDivider />
              <Typography variant="body-md" className="">
                {paymentInfo.details?.email}
              </Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};
