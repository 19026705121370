import { Elements } from '@stripe/react-stripe-js';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useStripeSubscription } from 'src/api/queries';
import {
  Button,
  ChangeCreditCardButton,
  Divider,
  NotificationBox,
} from 'src/components';
import { stripePromise } from 'src/stripePromise';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { useReauthorizePaymentMethod } from 'src/utils/useReauthorizePaymentMethod';

import { CardHeading } from './CardHeading';
import { CreditCardDetails } from './CreditCardDetails';
import { PaymentNotifications } from './PaymentNotifications';
import { WalletDetails } from './WalletDetails';

export const PaymentMethod = () => {
  return (
    <Elements stripe={stripePromise}>
      <WithStripeElements />
    </Elements>
  );
};

const WithStripeElements = () => {
  const subscription = useStripeSubscription();
  const reauthorizePayementMethod = useReauthorizePaymentMethod();
  const clientSecret =
    subscription.data?.paymentInformation?.requiresAction?.clientSecret;

  return (
    <div className="flex flex-col gap-16">
      <div className="flex items-start gap-8 xs:justify-between">
        <CardHeading>Payment method</CardHeading>
      </div>

      <Divider />

      <div className="flex flex-col gap-16 lm:flex-row lm:items-start">
        {subscriptionHelpers.paymentMethodIsWallet(subscription.data) ? (
          <WalletDetails />
        ) : (
          <CreditCardDetails />
        )}

        <div className="flex flex-col items-end gap-16 ls:flex-row ls:flex-wrap lm:flex-col">
          <ChangeCreditCardButton
            componentLocation={ComponentLocations.MANAGE_CONNECT.PAYMENT_PAGE}
          />

          {subscription.data?.access.canSeeFailedPaymentInformation &&
            subscription.data.paymentInformation?.requiresAction &&
            clientSecret && (
              <Elements stripe={stripePromise}>
                <Button
                  data-cy="reauthorize-payment-method-button"
                  size="medium"
                  loading={reauthorizePayementMethod.isPending}
                  onClick={() => reauthorizePayementMethod.mutate(clientSecret)}
                  className="w-full text-nowrap ls:w-fit"
                  analytics={{
                    text: 'Authorize payment',
                    location: ComponentLocations.MANAGE_CONNECT.PAYMENT_PAGE,
                    action: 'reauthorize payment',
                  }}
                >
                  Authorize payment
                </Button>
              </Elements>
            )}
        </div>
      </div>

      {reauthorizePayementMethod.error && (
        <NotificationBox
          variant="error"
          title="An error occured while trying to authorize payment"
          className="w-full"
        >
          {reauthorizePayementMethod.error?.message}
        </NotificationBox>
      )}

      {subscription.data?.access.canSeeFailedPaymentInformation && (
        <PaymentNotifications />
      )}
    </div>
  );
};
