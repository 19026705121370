import { CreditCard } from 'phosphor-react';

import { useStripeSubscription } from 'src/api/queries';
import { Button, EditPaymentMethodModal, Modal } from 'src/components';
import { subscriptionHasPaymentMethod } from 'src/utils/subscriptionPayment';
import { useVerifyPaymentMethod } from 'src/utils/useVerifyPaymentMethod';

export const ChangeCreditCardButton = ({
  className = '',
  componentLocation,
}: {
  className?: string;
  componentLocation: string;
}) => {
  const subscription = useStripeSubscription();
  const verifyPaymentMethod = useVerifyPaymentMethod();

  return (
    <Modal.Root>
      <Modal.Trigger asChild>
        <Button
          variant="tertiary"
          size="medium"
          className={`w-full whitespace-nowrap ls:w-fit ${className}`}
          data-cy="change-payment-card-button"
          loading={subscription.isPending || verifyPaymentMethod.isPending}
          disabled={subscription.isPending || verifyPaymentMethod.isPending}
          analytics={{
            text: 'Change payment card',
            location: componentLocation,
            action: 'open change payment card modal',
          }}
        >
          <CreditCard />
          <span>
            {subscriptionHasPaymentMethod(subscription.data) ? 'Change' : 'Add'}{' '}
            payment method
          </span>
        </Button>
      </Modal.Trigger>

      <EditPaymentMethodModal />
    </Modal.Root>
  );
};
