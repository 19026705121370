import { HTMLProps, ReactNode } from 'react';

import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useForm } from 'react-hook-form';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { FeedbackTargetValues } from 'src/api/endpoints/storeApi.types';
import {
  useAddFeedbackComment,
  useFeedbackValue,
} from 'src/api/queries/feedback';
import { cn } from 'src/utils/classNamesHelper';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { Button } from './Button';
import { Form } from './Form';
import { Link } from './Link';
import { NotificationBox } from './NotificationBox';

interface FeedbackProps {
  feedbackTargetId: FeedbackTargetValues;
  title: ReactNode;
}

export function Feedback({
  title,
  feedbackTargetId,
  ...props
}: HTMLProps<HTMLDivElement> & FeedbackProps) {
  const feedbackValue = useFeedbackValue(feedbackTargetId);
  const addFeedbackComment = useAddFeedbackComment(feedbackTargetId);
  const form = useForm<{
    comment: string;
  }>();

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center gap-24 rounded  px-16 py-32',
        props.className
      )}
    >
      {!feedbackValue.isSuccess && (
        <>
          <Typography variant="interface-md-medium" className="text-center">
            {title}
          </Typography>

          <div className="flex gap-16">
            <Button
              variant="tertiary"
              aria-label="Thumbs up feedback"
              size="medium"
              data-cy="feedback-thumbs-up-button"
              onClick={() => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location: feedbackTargetId,
                    action: 'feedback thumbs up',
                    text: '[thumbs up icon]',
                  })
                );
                feedbackValue.mutate({ feedbackValue: true });
              }}
              loading={
                feedbackValue.isPending &&
                feedbackValue.variables?.feedbackValue === true
              }
            >
              <ThumbsUp />
            </Button>

            <Button
              variant="tertiary"
              aria-label="Thumbs down feedback"
              size="medium"
              data-cy="feedback-thumbs-down-button"
              onClick={() => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location: feedbackTargetId,
                    action: 'feedback thumbs down',
                    text: '[thumbs down icon]',
                  })
                );
                feedbackValue.mutate({ feedbackValue: false });
              }}
              loading={
                feedbackValue.isPending &&
                feedbackValue.variables?.feedbackValue === false
              }
            >
              <ThumbsDown />
            </Button>
          </div>

          {feedbackValue.isError && (
            <NotificationBox variant="error" title="Something went wrong">
              We couldn&apos;t submit your feedback. Please try again later or
              contact support if the problem persists.
            </NotificationBox>
          )}
        </>
      )}

      {feedbackValue.isSuccess && !addFeedbackComment.isSuccess && (
        <>
          <Typography variant="interface-md-medium">
            Thanks, is there anything you&apos;d like to add?
          </Typography>

          <Form
            {...form}
            className="flex w-full animate-fade-in-up flex-col items-center gap-24"
            onSubmit={(values) => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location: feedbackTargetId,
                  action: 'feedback add comment',
                  text: values.comment,
                })
              );
              if (!feedbackValue.data?.id) {
                return;
              }

              addFeedbackComment.mutate({
                comment: values.comment,
                instanceId: feedbackValue.data.id,
              });
            }}
          >
            <div className="flex w-full flex-col items-center gap-8">
              <textarea
                {...form.register('comment', { required: true })}
                className="min-h-[100px] w-full max-w-[560px] rounded border border-neutral-light-8 bg-neutral-light-1 p-12"
                placeholder="Enter your answer..."
              />

              <Typography
                variant="interface-xs-regular"
                className="text-center leading-4"
              >
                If you need help or have questions,{' '}
                <Link inline to={SUPPORT_URL.CONTACT_SUPPORT}>
                  contact support
                </Link>
                .<br />
                Please don&apos;t include personal information.
              </Typography>
            </div>

            <Button
              type="submit"
              size="medium"
              variant="secondary"
              loading={addFeedbackComment.isPending}
              data-cy="feedback-submit-button"
            >
              Send feedback
            </Button>
          </Form>

          {addFeedbackComment.isError && (
            <NotificationBox variant="error" title="Something went wrong">
              We couldn&apos;t submit your comment. Please try again later or
              contact support if the problem persists.
            </NotificationBox>
          )}
        </>
      )}

      {addFeedbackComment.isSuccess && (
        <Typography variant="interface-md-medium">
          Thank you for your feedback!
        </Typography>
      )}
    </div>
  );
}
