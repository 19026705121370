import { User } from '@auth0/auth0-react';
import { ToOptions } from '@tanstack/react-router';

import {
  EnterpriseDomain,
  EnterpriseSettings,
} from 'src/api/endpoints/cloudEnterpriseAdminApi.types';
import {
  EnterpriseEnrollmentStatus,
  EnterpriseEnrollmentStep,
} from 'src/api/endpoints/cloudEnterpriseEnrollmentApi.types';

import { URLS } from './urls/urls';
import { userHelpers } from './userHelpers';

const getPrimaryDomain = (orgSettings?: EnterpriseSettings | null) =>
  orgSettings?.domains?.find((domain) => domain.isPrimary === true) ??
  undefined;

const isMigrationEnabled = (domain?: EnterpriseDomain) =>
  !!domain?.migrationEnabled;

const isDomainForwardingEnabled = (domain?: EnterpriseDomain) =>
  !!domain?.homeRealmDiscoveryEnabled;

const getEnrollmentStep = (
  enrollmentStatus?: EnterpriseEnrollmentStatus | null,
  user?: User
): EnterpriseEnrollmentStep => {
  if (!enrollmentStatus || !user) {
    return EnterpriseEnrollmentStep.NotStarted;
  }

  if (enrollmentStatus.enrollment === 'FINALIZED') {
    return EnterpriseEnrollmentStep.Finalized;
  }

  const hasMatchingOrgConnection =
    userHelpers.getOrganizationConnection(user) ===
    enrollmentStatus.connectionId;

  if (
    enrollmentStatus.signInUrl &&
    enrollmentStatus.base64Cert &&
    hasMatchingOrgConnection &&
    userHelpers.getOrganizationId(user)
  ) {
    return EnterpriseEnrollmentStep.Confirm;
  }

  if (enrollmentStatus.signInUrl && enrollmentStatus.base64Cert) {
    return EnterpriseEnrollmentStep.VerifySAML;
  }

  if (enrollmentStatus.domainVerification === 'OK') {
    return EnterpriseEnrollmentStep.SetupSAML;
  }

  if (enrollmentStatus.primaryDomain) {
    return EnterpriseEnrollmentStep.VerifyDomain;
  }

  return EnterpriseEnrollmentStep.CreateOrganization;
};

const getEnrollmentStepUrl = (
  step: EnterpriseEnrollmentStep
): ToOptions['to'] => {
  switch (step) {
    case EnterpriseEnrollmentStep.Finalized:
    case EnterpriseEnrollmentStep.Confirm:
      return URLS.ENTERPRISE_CONFIRM_SETUP;
    case EnterpriseEnrollmentStep.VerifySAML:
      return URLS.ENTERPRISE_VERIFY_SAML;
    case EnterpriseEnrollmentStep.SetupSAML:
      return URLS.ENTERPRISE_SETUP_SAML;
    case EnterpriseEnrollmentStep.VerifyDomain:
      return URLS.ENTERPRISE_VERIFY_DOMAIN;
    case EnterpriseEnrollmentStep.CreateOrganization:
      return URLS.ENTERPRISE_CREATE_ORGANIZATION;
    case EnterpriseEnrollmentStep.NotStarted:
    default:
      return URLS.ENTERPRISE_SETUP;
  }
};

const getVisibleEnrollmentSteps = () =>
  Object.values(EnterpriseEnrollmentStep).slice(1, -1);

const getEnrollmentStepName = (step: EnterpriseEnrollmentStep) => {
  switch (step) {
    case EnterpriseEnrollmentStep.Finalized:
    case EnterpriseEnrollmentStep.Confirm:
      return 'Complete';
    case EnterpriseEnrollmentStep.VerifySAML:
      return 'Verify SAML';
    case EnterpriseEnrollmentStep.SetupSAML:
      return 'Setup SAML';
    case EnterpriseEnrollmentStep.VerifyDomain:
      return 'Verify domain';
    case EnterpriseEnrollmentStep.CreateOrganization:
      return 'Create organization';
    case EnterpriseEnrollmentStep.NotStarted:
    default:
      return '';
  }
};

const isEnrollmentStepCompleted = (
  step: EnterpriseEnrollmentStep,
  enrollmentStatus: EnterpriseEnrollmentStatus
) => step < getEnrollmentStep(enrollmentStatus);

export const enterpriseHelpers = {
  getEnrollmentStep,
  getEnrollmentStepName,
  getEnrollmentStepUrl,
  getPrimaryDomain,
  getVisibleEnrollmentSteps,
  isDomainForwardingEnabled,
  isEnrollmentStepCompleted,
  isMigrationEnabled,
};
