import { useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowCircleLeft } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  queryKeys,
  useChangePaymentMethodSetupIntent,
  useStripeSubscription,
} from 'src/api/queries';
import { Button, Modal, NotificationBox, Spinner } from 'src/components';
import { stripePromise } from 'src/stripePromise';
import { subscriptionHasPaymentMethod } from 'src/utils/subscriptionPayment';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { EditPaymentMethod } from './EditPaymentMethod';

interface EditPaymentMethodWrapperProps {
  subscriptionId?: string;
}

const EditPaymentMethodWrapper = ({
  subscriptionId,
}: EditPaymentMethodWrapperProps) => {
  const queryClient = useQueryClient();
  const changePaymentMethodSetupIntent = useChangePaymentMethodSetupIntent();

  useEffect(() => {
    if (!subscriptionId) return;
    changePaymentMethodSetupIntent.mutate(
      { subscriptionId },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            queryKeys.subscriptions.createSetupIntent,
            data
          );
        },
      }
    );
  }, [subscriptionId]);

  if (!subscriptionId) return <Spinner />;

  if (changePaymentMethodSetupIntent.isError) {
    return (
      <>
        <NotificationBox
          title="Something went wrong"
          data-cy="edit-payment-method-create-setup-intent-failed"
          variant="error"
        >
          <Typography variant="body-sm-regular" as="p">
            Please try again, or{' '}
            <a href={SUPPORT_URL.HOME} target="_blank" rel="noreferrer">
              contact support
            </a>{' '}
            if the problem persists. We are sorry about the inconvenience.
          </Typography>
        </NotificationBox>

        <Modal.Close asChild>
          <Button
            variant="secondary"
            analytics={{
              text: 'Back',
              location: ComponentLocations.MANAGE_CONNECT.MAIN,
              action: 'close edit payment method modal',
            }}
          >
            <ArrowCircleLeft />
            <span>Back</span>
          </Button>
        </Modal.Close>
      </>
    );
  }

  if (
    changePaymentMethodSetupIntent.isPending ||
    !changePaymentMethodSetupIntent.data?.clientSecret
  ) {
    return <Spinner />;
  }

  const options: StripeElementsOptions = {
    clientSecret: changePaymentMethodSetupIntent.data.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <EditPaymentMethod
        setupIntent={changePaymentMethodSetupIntent}
        subscriptionId={subscriptionId}
      />
    </Elements>
  );
};

export const EditPaymentMethodModal = () => {
  const subscription = useStripeSubscription();

  return (
    <Modal.Content
      title={`${
        subscriptionHasPaymentMethod(subscription.data) ? 'Change' : 'Add'
      } payment method`}
    >
      <EditPaymentMethodWrapper subscriptionId={subscription.data?.id} />
    </Modal.Content>
  );
};
