import { redirect } from '@tanstack/react-router';

import { AccessDenied } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import {
  CheckoutAllowedResponse,
  CheckoutType,
} from 'src/api/endpoints/storeApi.types';

export function handleCheckoutAllowedRedirect(
  checkoutType: CheckoutType,
  data: CheckoutAllowedResponse['data'] | null
) {
  if (!data) {
    tracker.trackEvent(
      new AccessDenied({
        action: `Visit ${checkoutType} page. No allowed data available.`,
      })
    );
    throw redirect({ to: '/' });
  }

  if (checkoutType in data === false) {
    tracker.trackEvent(
      new AccessDenied({
        action: `Visit ${checkoutType} page. No allowed data for this checkout available.`,
      })
    );
    throw new Error('Unable to check if checkout is allowed');
  }

  const checkout = data[checkoutType];

  if (checkout.allowed !== true) {
    tracker.trackEvent(
      new AccessDenied({
        action: `Visit ${checkoutType} page. Denied reason: "${checkout.reason}"`,
      })
    );

    if (checkout.reason === 'NO_OFFER') {
      throw redirect({ to: '/' });
    }

    throw redirect({ to: '/subscriptions' });
  }
}
