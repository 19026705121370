import { HTMLProps } from 'react';

import { createFileRoute, Outlet } from '@tanstack/react-router';

import { Footer } from 'src/apps/footer/Footer';
import { Header } from 'src/apps/header/Header';
import { SomethingWentWrong, Spinner } from 'src/components';
import { cn } from 'src/utils/classNamesHelper';

function Wrapper({ children, className, ...props }: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'flex grow animate-fade-in flex-col items-center justify-between',
        className
      )}
      {...props}
    >
      <div className="w-full">
        <Header variant="full" />
      </div>
      {children}
      <Footer />
    </div>
  );
}

export const Route = createFileRoute('/_public/_layout')({
  wrapInSuspense: true,
  errorComponent: ({ error }) => (
    <Wrapper>
      <SomethingWentWrong error={error} />
    </Wrapper>
  ),
  pendingComponent: () => (
    <Wrapper>
      <Spinner />
    </Wrapper>
  ),
  component: () => (
    <Wrapper>
      <Outlet />
    </Wrapper>
  ),
});
