import { useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import toast from 'react-hot-toast';

import {
  useEnableEnterpriseDomainForwarding,
  useEnterpriseOrgSettings,
} from 'src/api/queries/enterpriseAdmin';
import { IS_DEVELOPMENT } from 'src/config';
import { enterpriseHelpers } from 'src/utils/enterpriseHelpers';

import { Modal, Tag } from '.';
import { Button } from './Button';
import { NotificationBox } from './NotificationBox';

export function StartEnterpriseDomainForwardingNotification() {
  const orgSettings = useEnterpriseOrgSettings();
  const primaryDomain = enterpriseHelpers.getPrimaryDomain(orgSettings.data);
  const enableDomainForwarding = useEnableEnterpriseDomainForwarding();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-24 border p-24">
      <div className="flex flex-col items-center gap-24 lm:flex-row">
        <div>
          <Typography variant="heading-subtle-xl" as="h3">
            Complete migration and activate domain forwarding
          </Typography>
          <Typography variant="body-md-regular">
            When you decide that all users are ready, you can activate domain
            forwarding, which forces all users from the domain to authenticate
            via SSO. At this point the migration period is completed and login
            via legacy credentials is no longer supported.
          </Typography>
        </div>

        <Modal.Root
          open={isOpen}
          onOpenChange={(newState) => setIsOpen(newState)}
        >
          {IS_DEVELOPMENT ? (
            <Modal.Trigger asChild>
              <Button
                disabled={!primaryDomain}
                variant="primary"
                className="w-full whitespace-nowrap ls:w-fit"
              >
                Complete migration
              </Button>
            </Modal.Trigger>
          ) : (
            <Tag variant="default" className="whitespace-nowrap">
              Available soon
            </Tag>
          )}

          <Modal.Content className="flex max-w-3xl flex-col gap-24">
            <Typography variant="heading-lg" as="h2">
              Are you sure you want to continue?
            </Typography>
            <Typography variant="body-md-regular">
              Activating domain forwarding will force all users from the domain
              to authenticate via SSO. At this point, login via legacy
              credentials is no longer supported.
            </Typography>

            <NotificationBox
              variant="warning"
              title="Make sure users are added to the IdP before activating domain forwarding"
            >
              If you have not added users to your IdP, they will not be able to
              access the application, as they will be unable to authenticate
              through SSO.
            </NotificationBox>

            <div className="flex justify-center gap-24">
              <Modal.Close asChild>
                <Button variant="secondary">Cancel</Button>
              </Modal.Close>
              <Button
                variant="primary"
                disabled={enableDomainForwarding.isPending}
                loading={enableDomainForwarding.isPending}
                onClick={() => {
                  enableDomainForwarding.mutate(
                    { domain: primaryDomain?.address },
                    {
                      onSuccess: () => {
                        toast.success('Migration completed');
                        setIsOpen(false);
                      },
                    }
                  );
                }}
              >
                Continue
              </Button>
            </div>

            {enableDomainForwarding.isError && (
              <NotificationBox variant="error" title="Something went wrong">
                {enableDomainForwarding.error.message ||
                  'Please try again later or contact support if the problem persists.'}
              </NotificationBox>
            )}
          </Modal.Content>
        </Modal.Root>
      </div>
    </div>
  );
}
