import { Typography } from '@remarkable/ark-web';

import { useCart } from 'src/api/queries';
import {
  CardContent,
  Feedback,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';

import { CheckoutFormWithoutCard } from '../components/CheckoutWithoutCard';

export const DmOfferCheckoutPage = () => {
  const cart = useCart();

  if (cart.setup.isPending || cart.country.isPending) {
    return <Spinner />;
  }
  if (cart.setup.isError || cart.country.isError) {
    return <SomethingWentWrong />;
  }
  return (
    <AppPage.Content className="max-w-ll">
      <Sheet>
        <CardContent className="flex flex-col gap-16">
          <Typography variant="heading-md">Activate Connect</Typography>
          <Typography variant="body-lg-regular">
            Your&apos;e a few steps away from seamless note-taking and
            successful transfer of files to your new device.
          </Typography>
        </CardContent>

        <CheckoutFormWithoutCard cart={cart} />
      </Sheet>

      <Feedback
        className="my-32"
        feedbackTargetId="store-dm-offer-checkout"
        title="Did you find what you were looking for?"
      />
    </AppPage.Content>
  );
};
