import { Middleware } from 'openapi-fetch';

import { HTTPError } from '../../createApiClient.types';

/** Used for enabling react query error handling */
export const throwOnErrorResponse: Middleware = {
  onResponse({ request, response }) {
    if (!response.ok) {
      const error = new HTTPError(response, request);
      console.error(error);
      throw error;
    }

    return response;
  },
};
