/* eslint-disable @typescript-eslint/unbound-method */

/* eslint-disable no-console */
import loglevel, { LogLevelNumbers } from 'loglevel';

import { IS_DEVELOPMENT, IS_STAGING } from 'src/config';

import { categoryLevel } from './logger.categories';
import { getColorForString } from './logger.colorpicker';
import { createTimestamp } from './logger.timestamp';

function log(config: {
  message?: string;
  category?: string;
  timestamp?: Date | number | string;
  level?: LogLevelNumbers;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}) {
  if (IS_DEVELOPMENT || IS_STAGING) {
    const { message, timestamp = new Date(), category } = config;
    const colorizer = getColorForString(category);

    const timestampFormatted = createTimestamp(timestamp);
    const categoryFormatted = category ? `${colorizer(category)}:` : '';
    const messageFormatted = `${categoryFormatted} ${message ?? ''}`.trim();

    let logFn = loglevel.log;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    let level = config.level;

    if (category && level === undefined && category in categoryLevel) {
      level = categoryLevel[category as keyof typeof categoryLevel];
    }

    if (level === loglevel.levels.TRACE) {
      logFn = loglevel.trace;
    }
    if (level === loglevel.levels.DEBUG) {
      logFn = loglevel.debug;
    }
    if (level === loglevel.levels.INFO) {
      logFn = loglevel.info;
    }
    if (level === loglevel.levels.WARN) {
      logFn = loglevel.warn;
    }
    if (level === loglevel.levels.ERROR) {
      logFn = loglevel.error;
    }

    if (config.data !== undefined) {
      logFn(`${timestampFormatted} ${messageFormatted}`, config.data);
    } else {
      logFn(`${timestampFormatted} ${messageFormatted}`);
    }
  }
}

loglevel.setDefaultLevel('info');

export const logger = {
  log,
};
