import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { useStripeSubscription, useSubscriptionMembers } from 'src/api/queries';
import { Spinner } from 'src/components';
import { FlexDivider } from 'src/components/FlexDivider';
import { TextWithLabel } from 'src/components/TextWithLabel';
import { isBillableSeat } from 'src/utils/isBillableSeat';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { subscriptionHasPaymentMethod } from 'src/utils/subscriptionPayment';
import { subscriptionTrialWillCancel } from 'src/utils/subscriptionTrialWillCancel';

import { formatDate } from '../../../../utils/formatDate';
import { useFormattedPaymentInfo } from '../utils/getFormattedPaymentInfo';

export const NextBillingDetails = () => {
  const subscription = useStripeSubscription();
  const members = useSubscriptionMembers();
  const cardDetails = useFormattedPaymentInfo(subscription.data);
  const billableSeats = useMemo(
    () => members.data?.filter((member) => isBillableSeat(member.seat)) ?? [],
    [members.data]
  );

  if (subscription.isPending) {
    return <Spinner />;
  }
  if (subscription.isError || !subscription.data) {
    return (
      <Typography variant="body-md-regular">
        No billing details available
      </Typography>
    );
  }

  if (subscriptionHelpers.isCancelled(subscription.data)) {
    return (
      <Typography variant="body-md-regular">
        Your subscription has been cancelled and will expire on{' '}
        {formatDate(subscription.data.paymentInformation.cancellationAt)}
      </Typography>
    );
  }

  if (subscriptionTrialWillCancel(subscription.data)) {
    return (
      <Typography variant="body-md-regular">
        Your subscription ends{' '}
        {formatDate(subscription.data.paymentInformation?.nextInvoice?.dueDate)}
      </Typography>
    );
  }

  if (!subscriptionHasPaymentMethod(subscription.data)) {
    return (
      <Typography variant="body-md-regular">
        No billing details available
      </Typography>
    );
  }

  return (
    <div className="flex w-full flex-col flex-wrap gap-16 lm:flex-row">
      <TextWithLabel label="Date" className="flex-1">
        {cardDetails?.nextBilling}
      </TextWithLabel>

      <FlexDivider />

      <TextWithLabel label="Subscription" className="flex-1">
        Connect
      </TextWithLabel>

      <FlexDivider />

      {subscriptionHelpers.isManagementTypeSeats(subscription.data) && (
        <>
          <TextWithLabel
            data-cy="seats-on-next-billing"
            label="Seats"
            className="flex-1"
          >
            {billableSeats.length}
          </TextWithLabel>

          <FlexDivider />
        </>
      )}

      <TextWithLabel label="Amount" className="flex-1">
        {cardDetails?.nextBillingAmount}
      </TextWithLabel>
    </div>
  );
};
