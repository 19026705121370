import { useMutation, useQueryClient } from '@tanstack/react-query';

import { storeApi } from 'src/api/endpoints';
import { queryKeys } from 'src/api/queries';

export const useCheckoutWithoutCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.checkout.withoutCard,
    mutationFn: storeApi.checkoutWithoutCard,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};
