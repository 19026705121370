import { useAuth0 } from '@auth0/auth0-react';
import { Check, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import {
  useAccountLinkingListener,
  useAuth0Claims,
  useLoginToLinkableAccount,
} from 'src/api/queries/user';
import {
  Button,
  Link,
  NotificationBox,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';
import { useHasLinkedAccounts } from '../utils/useHasLinkedAccounts';

export function VerifySamlPage() {
  const accountLinkingListener = useAccountLinkingListener({
    returnUrl: URLS.ENTERPRISE_CONFIRM_SETUP,
  });
  const loginToLinkableAccount = useLoginToLinkableAccount();
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const linkedStatus = useHasLinkedAccounts();
  const { user } = useAuth0();
  const claims = useAuth0Claims();

  const isLoggedInToSSO = !!claims.data?.org_id;

  if (accountLinkingListener.isPending || linkedStatus.isPending) {
    return <Spinner />;
  }

  if (linkedStatus.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Verify SAML
            </Typography>

            <CancelSetupModal />
          </div>

          <div className="flex justify-between gap-24 border-y py-16">
            <Typography variant="body-md-regular">
              Owner: {user?.email ?? 'No email'}
            </Typography>

            {isLoggedInToSSO ? (
              <div className="flex items-center gap-8 text-pen-blue">
                <Check size={24} />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-8 text-neutral-dark-1">
                <X size={24} />
                <span>Not verified</span>
              </div>
            )}
          </div>

          {isLoggedInToSSO ? (
            <Typography variant="body-md-regular">
              Your SAML setup has been verified.
            </Typography>
          ) : (
            <div className="flex flex-col gap-16">
              <Typography variant="body-md-regular">
                To verify the SAML setup:
              </Typography>

              <ol className="list-inside list-decimal">
                <li>Add your email address to your identity provider (IdP)</li>
                <li>
                  Log in with SSO to verify and migrate to an enterprise account
                </li>
              </ol>

              <div className="w-full bg-neutral-light-3 px-12 py-8">
                <Typography variant="body-sm-regular">
                  By verifying, you accept migration of account and the
                  following:
                </Typography>

                <ul className="list-disc pl-24">
                  <li>
                    <Typography variant="body-sm-regular">
                      Transfer of control and ownership of your reMarkable
                      content to the Business.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body-sm-regular">
                      Termination of applicable reMarkable Accounts Terms and
                      Conditions we have with you.
                    </Typography>
                  </li>
                </ul>

                <Link inline to="#" className="text-14">
                  Read more
                </Link>
              </div>
            </div>
          )}

          <div className="flex justify-center gap-24">
            <Button as="a" variant="secondary" to={URLS.ENTERPRISE_SETUP_SAML}>
              Previous
            </Button>
            {isLoggedInToSSO ? (
              <Button
                as="a"
                to={URLS.ENTERPRISE_CONFIRM_SETUP}
                variant="primary"
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={
                  loginToLinkableAccount.isPending || linkedStatus.isPending
                }
                loading={
                  loginToLinkableAccount.isPending || linkedStatus.isPending
                }
                onClick={() => {
                  if (typeof enrollmentStatus.data?.connectionId !== 'string')
                    return;

                  if (!linkedStatus.isSuccess) return;

                  loginToLinkableAccount.mutate({
                    connection: enrollmentStatus.data.connectionId,
                    startLinking: !linkedStatus.data.isLinked,
                  });
                }}
              >
                {linkedStatus.data.isLinked
                  ? 'Login to SSO'
                  : 'Link account to SSO'}
              </Button>
            )}
          </div>

          <NotificationBox error={loginToLinkableAccount.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
