function getRemarkableNameFromSerialNumber(
  serialNumber?: string,
  fallback = 'reMarkable'
) {
  if (!serialNumber) {
    return fallback;
  }
  if (serialNumber.startsWith('RM10')) {
    return 'reMarkable 1';
  }
  if (serialNumber.startsWith('RM11')) {
    return 'reMarkable 2';
  }
  if (serialNumber.startsWith('RM02')) {
    return 'reMarkable Paper Pro';
  }

  return fallback;
}

export function getDeviceName(
  device: { type?: string; serialNumber?: string },
  fallback?: string
) {
  switch (device.type) {
    case 'desktop-linux':
      return 'Linux app';
    case 'desktop-macos':
      return 'MacOS app';
    case 'desktop-windows':
      return 'Windows app';
    case 'mobile-android':
      return 'Android app';
    case 'mobile-ios':
      return 'iOS app';
    case 'browser-chrome':
      return 'Chrome extension';
    case 'remarkable':
      return getRemarkableNameFromSerialNumber(device.serialNumber);
    case 'remarkable-rm1':
      return getRemarkableNameFromSerialNumber(
        device.serialNumber,
        'reMarkable 1'
      );
    case 'remarkable-rm2':
      return getRemarkableNameFromSerialNumber(
        device.serialNumber,
        'reMarkable 2'
      );
    case 'remarkable-ferrari':
      return getRemarkableNameFromSerialNumber(
        device.serialNumber,
        'reMarkable Paper Pro'
      );
    default: {
      const emulatorPostfix = device.type?.includes('emulator')
        ? ' (emulator)'
        : '';

      if (device.type?.includes('word')) {
        return 'Word extension';
      }

      if (device.type?.includes('powerpoint')) {
        return 'PowerPoint extension';
      }

      if (device.type?.includes('desktop')) {
        return 'Desktop app' + emulatorPostfix;
      }

      if (device.type?.includes('mobile')) {
        return 'Mobile app' + emulatorPostfix;
      }

      if (device.type?.includes('extension')) {
        return 'Browser extension' + emulatorPostfix;
      }

      if (device.type?.includes('remarkable')) {
        return (
          getRemarkableNameFromSerialNumber(device.serialNumber) +
          emulatorPostfix
        );
      }

      if (device.type?.includes('emulator')) {
        return (
          getRemarkableNameFromSerialNumber(device.serialNumber) +
          emulatorPostfix
        );
      }

      return getRemarkableNameFromSerialNumber(
        device.serialNumber,
        fallback ?? 'Unknown device'
      );
    }
  }
}
