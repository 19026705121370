import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import rMSyncFiles from 'src/assets/img/rM_sync_files_DM.webp';
import { Button } from 'src/components';
import { ImageWithFallback } from 'src/components/ImageWithFallback';
import { StackedSheet } from 'src/components/StackedSheet';
import { URLS } from 'src/utils/urls/urls';

export const DmOffer = ({
  analytics,
}: {
  analytics?: {
    text: string;
    location: string;
    action: string;
  };
}) => (
  <StackedSheet dataCy="time-to-sync-landing">
    <div className=" flex flex-col  lm:flex-row">
      <div className="lm:3/5 mb-32 flex flex-1 flex-col gap-16 pr-64 text-left lm:my-auto">
        <Typography variant="heading-2xl">
          Sync files to your new paper tablet
        </Typography>
        <Typography variant="body-md-regular">
          Activate your free 30-day Connect trial to sync your notes to the
          cloud and access them on multiple devices.
        </Typography>

        <Button
          as="a"
          variant="primary"
          to={URLS.STORE_DM_OFFER_CHECKOUT}
          className="w-full lm:w-fit"
          data-cy="landing-next-step"
          analytics={analytics}
        >
          <span data-cy={`dmOffer-activate-button`}>Sync files</span>
          <ArrowRight size={32} />
        </Button>
        <Typography variant="interface-sm-regular">
          Your trial will automatically end after 30 days if you don&apos;t add
          a payment method.
        </Typography>
      </div>

      <div className="w-full flex-1 lm:w-2/5">
        <ImageWithFallback
          className="justify-center lm:flex"
          imgClassName="lm:max-h-full py-8 max-h-[200px] w-full object-cover"
          sources={[
            {
              type: 'webp',
              url: rMSyncFiles,
            },
          ]}
        />
      </div>
    </div>
  </StackedSheet>
);
