import { useEffect } from 'react';

import { ValidateResult } from 'react-hook-form';

import { useCart } from 'src/api/queries';
import { Input, Spinner } from 'src/components';

import { useCheckoutForm } from './useCheckoutForm';

interface InputZipCodeProps {
  cart: ReturnType<typeof useCart>;
  country?: string;
  disabled?: boolean;
}

export const InputZipCode = ({
  cart,
  disabled,
  country,
}: InputZipCodeProps) => {
  const { getValues, trigger, watch } = useCheckoutForm();
  const state = watch('state');
  const postalCode = watch('postalCode');

  // Revalidate postal code when state changes.
  useEffect(() => {
    if (!state || !postalCode) return;

    void trigger('postalCode');
  }, [state, postalCode]);

  const updateAndValidateTax = async (zipCode = '') =>
    await new Promise<ValidateResult>((resolve) => {
      const { country: countryFormValue, state, city } = getValues();
      cart.updateTax.mutate(
        {
          country: country ?? countryFormValue,
          county: state ?? '',
          zip: zipCode,
          city,
        },
        {
          onError: () => resolve(false),
          onSuccess: () => resolve(true),
        }
      );
    });

  return (
    <Input
      name="postalCode"
      label={<>Zip / Postal code {disabled && <Spinner inline />}</>}
      inputMode="numeric" // defaults to numberpad on virtual keyboards
      className={disabled ? 'cursor-wait' : ''}
      required
      disabled={disabled}
      data-cy="postal-code-input"
      validate={{
        length: (value: string | undefined) => {
          if (!value) return 'ZIP code must be provided';
          const zipPattern = /^\d{5}([-]\d{4})?$/i;
          return (
            zipPattern.test(value) ||
            'Please enter a valid zip code for the United States, for example 90210 or 90210-1234.'
          );
        },
        zipCode: updateAndValidateTax,
      }}
    />
  );
};
