import { Typography } from '@remarkable/ark-web';
import { useFormContext } from 'react-hook-form';

import { useCart } from 'src/api/queries';
import { CA_PROVINCES, US_STATES } from 'src/utils/countryList';
import { formatAmount } from 'src/utils/productUtils';

import { CheckoutFormState } from './Checkout.types';

export const CartItemConnect = () => {
  const cart = useCart();
  const { watch } = useFormContext<CheckoutFormState>();
  const state = watch('state');

  const priceWithTax = formatAmount(
    cart.details.data?.data.productPrice.total ?? 0,
    cart.details.data?.data.currency
  );
  const priceWithoutTax = formatAmount(
    cart.details.data?.data.productPrice.base ?? 0,
    cart.details.data?.data.currency
  );
  const tax = formatAmount(
    cart.details.data?.data.productPrice.tax ?? 0,
    cart.details.data?.data.currency
  );

  const includesTax = cart.details.data?.data.tax.isIncluded;

  const isUs = cart.country.data?.value === 'US';
  const isCanada = cart.country.data?.value === 'CA';

  const stateLabel =
    isUs && state
      ? US_STATES.find((s) => s.value === state)?.label
      : isCanada && state
      ? CA_PROVINCES.find((s) => s.value === state)?.label
      : state;

  const countryLabel =
    isUs && stateLabel ? 'US' : cart.country.data?.label ?? '';

  const taxLocation = stateLabel
    ? `${stateLabel}, ${countryLabel}`
    : countryLabel;

  return (
    <>
      <div
        className="flex flex-row items-center justify-between font-semibold"
        data-cy="connect-plan-row-price"
      >
        <Typography variant="body-lg-bold">Connect subscription</Typography>
        <Typography variant="body-lg-bold">
          {includesTax ? priceWithTax : priceWithoutTax}
        </Typography>
      </div>
      <div className="flex flex-row items-center justify-between">
        <p className="m-0 text-16">
          {isUs ? 'Sales tax' : 'VAT'} to {taxLocation}
        </p>
        <p className="m-0 text-16">{includesTax ? 'included' : tax}</p>
      </div>
      {!includesTax && (
        <div className="flex flex-row items-center justify-between font-semibold">
          <Typography variant="body-lg-bold">Total</Typography>
          <Typography variant="body-lg-bold">{priceWithTax}</Typography>
        </div>
      )}
    </>
  );
};
