import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { ExperimentApplied } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { queryKeys } from 'src/api/queries';
import { ConsentLevel, getConsentLevel } from 'src/consent';
import {
  Experiments,
  ExperimentVariant,
  ExperimentKeys,
  findAppliedVariant,
  getRandomVariant,
} from 'src/utils/Experiments';

export const getAppliedExperimentVariant = async (
  experimentKey: ExperimentKeys,
  userId?: string
) => {
  if (!userId || getConsentLevel() !== ConsentLevel.ALL) {
    return ExperimentVariant.CG; // Default to control group;
  }

  const experiment = Experiments[experimentKey];
  if (!experiment) {
    return ExperimentVariant.CG;
  }

  const { id, name, variants } = experiment;

  let selectedVariant = findAppliedVariant(id, variants);

  if (!selectedVariant) {
    selectedVariant = await getRandomVariant(variants, userId);

    tracker.setExperiment({
      name: name,
      id: id,
      variant: selectedVariant.variantName,
    });
    tracker.trackEvent(
      new ExperimentApplied({
        experiment_id: id,
        experiment_variant: selectedVariant.variantName,
      })
    );
    Sentry.setTag('experimentName', `${name}:${selectedVariant.variantName}`);
    Sentry.setTag('experimentId', id);
  }

  return selectedVariant.variantName;
};

export const useGetAppliedExperimentVariant = (
  experimentKey: ExperimentKeys,
  userId?: string
) => {
  return useQuery({
    queryKey: [
      queryKeys.experiment.connectOfferRollback,
      experimentKey,
      userId,
    ],
    queryFn: () => getAppliedExperimentVariant(experimentKey, userId),
  });
};
