import * as Sentry from '@sentry/react';
import { loadStripe as loadStripeJS, Stripe } from '@stripe/stripe-js';

import { auth0Client } from 'src/auth0Client';
import { resolveStripePromise } from 'src/stripePromise';

const MAX_LOADING_ATTEMPTS = 3;

export async function loadStripe(
  location: string,
  loadingAttempt = 1
): Promise<Stripe | null> {
  Sentry?.addBreadcrumb({
    category: 'Stripe',
    message: `loadStripe triggered from "${location}"`,
  });

  const auth = await auth0Client;

  if (!auth.isAuthenticated) {
    Sentry?.captureEvent({
      message: 'Stripe loading aborted: Not authenticated',
    });
    return null;
  }

  Sentry?.addBreadcrumb({
    category: 'Stripe',
    message: `Loading stripe...`,
  });

  const stripeOrNull = await loadStripeJS(
    import.meta.env.PUBLIC_STRIPE_PUBLISHABLE_KEY ?? ''
  ).catch((reason) => {
    if (loadingAttempt < MAX_LOADING_ATTEMPTS) {
      Sentry?.addBreadcrumb({
        category: 'Stripe',
        message: `Stripe failed to load on first attempt, retrying for attempt ${
          loadingAttempt + 1
        }`,
      });

      // Try one more time
      return loadStripe(location, loadingAttempt + 1);
    }
    Sentry?.captureEvent({
      message: 'Stripe failed to load',
      extra: {
        reason: reason,
      },
    });

    return null;
  });

  Sentry?.addBreadcrumb({
    category: 'Stripe',
    message: 'Stripe loaded successfully',
  });

  resolveStripePromise(stripeOrNull);
  return stripeOrNull;
}
