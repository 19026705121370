import { useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import toast from 'react-hot-toast';

import {
  useEnableEnterpriseMigration,
  useEnterpriseOrgSettings,
} from 'src/api/queries/enterpriseAdmin';
import { enterpriseHelpers } from 'src/utils/enterpriseHelpers';

import { Modal } from '.';
import { Button } from './Button';
import { NotificationBox } from './NotificationBox';

export function StartEnterpriseMigrationNotifiaction() {
  const orgSettings = useEnterpriseOrgSettings();
  const primaryDomain = enterpriseHelpers.getPrimaryDomain(orgSettings.data);
  const startMigration = useEnableEnterpriseMigration();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-24 border p-24">
      <div className="flex flex-col items-center gap-24 lm:flex-row">
        <div>
          <Typography variant="heading-subtle-xl" as="h3">
            Start migration and mark all users as claimed
          </Typography>
          <Typography variant="body-md-regular">
            All users with email addresses under your domain are flagged for
            migration, regardless of whether they are added to your identity
            provider (IdP) or not. During this period, users will still access
            their existing accounts but will see a banner prompting them to
            migrate to the new SSO system.
          </Typography>
        </div>

        <Modal.Root
          open={isOpen}
          onOpenChange={(newState) => setIsOpen(newState)}
        >
          <Modal.Trigger asChild>
            <Button
              disabled={!primaryDomain}
              variant="primary"
              className="w-full whitespace-nowrap ls:w-fit"
            >
              Start migration
            </Button>
          </Modal.Trigger>

          <Modal.Content className="flex max-w-3xl flex-col gap-24">
            <Typography variant="heading-lg" as="h2">
              Are you sure you want to continue?
            </Typography>
            <Typography variant="body-md-regular">
              Users with email addresses under your domain, whether added to
              your identity provider (IdP) or not, will be flagged for migration
              and notified by email. They can continue accessing their accounts
              but will see a banner prompting them to switch to the new SSO
              system.
            </Typography>

            <NotificationBox
              variant="warning"
              title="Make sure users are added to the IdP before starting the migration phase!"
            >
              If you have not added users to your IdP, they will not be able
              complete the migration, as they will be unable to authenticate
              through SSO.
            </NotificationBox>

            <div className="flex justify-center gap-24">
              <Modal.Close asChild>
                <Button variant="secondary">Cancel</Button>
              </Modal.Close>
              <Button
                variant="primary"
                disabled={startMigration.isPending}
                loading={startMigration.isPending}
                onClick={() => {
                  startMigration.mutate(
                    { domain: primaryDomain?.address },
                    {
                      onSuccess: () => {
                        toast.success('Migration started');
                        setIsOpen(false);
                      },
                    }
                  );
                }}
              >
                Continue
              </Button>
            </div>

            {startMigration.isError && (
              <NotificationBox variant="error" title="Something went wrong">
                {startMigration.error.message ||
                  'Please try again later or contact support if the problem persists.'}
              </NotificationBox>
            )}
          </Modal.Content>
        </Modal.Root>
      </div>
    </div>
  );
}
