import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { SomethingWentWrong, Spinner } from 'src/components';

export const Route = createFileRoute('/_public/logout')({
  component: () => <LogoutPage />,
});

export function LogoutPage() {
  const auth = useAuth0();

  const logout = useMutation({
    mutationFn: () => {
      return auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    onError: (error) => {
      Sentry?.captureEvent({
        message: 'Logout failed',
        extra: {
          error: error.message,
        },
      });
    },
  });

  useEffect(() => {
    if (logout.isIdle && !auth.isLoading) {
      logout.mutate();
    }
  }, [logout.isIdle, auth.isLoading]);

  if (logout.error) {
    return <SomethingWentWrong error={logout.error} />;
  }

  return <Spinner />;
}
