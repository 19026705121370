/* eslint-disable no-console */
import { useEffect } from 'react';

import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';

import { AccountLinkingFailed, AccountLinkingSucceeded } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useHasLinkedAccounts } from 'src/apps/enterprise/app/utils/useHasLinkedAccounts';
import { auth0Client } from 'src/auth0Client';
import { CONFIG, IS_DEVELOPMENT, IS_STAGING } from 'src/config';
import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';
import { userHelpers } from 'src/utils/userHelpers';

import { cloudProfileApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export function useAuth0Claims() {
  const auth0 = useAuth0();

  return useQuery({
    queryKey: queryKeys.user.claims,
    queryFn: async () => {
      return (await (auth0.getIdTokenClaims() as Promise<RmClaims>)) ?? null;
    },
  });
}

export const queryAccessTokenClaims = queryOptions({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: ['user', 'token'],
  queryFn: async () => {
    const auth0 = await auth0Client;
    return jwtDecode<RmClaims>(await auth0.getAccessTokenSilently());
  },
});

export const useAccessTokenClaims = () =>
  useSuspenseQuery(queryAccessTokenClaims);

export function useEnterprisePermissions() {
  return useQuery({
    ...queryAccessTokenClaims,
    select: (data) => ({
      readMembers: userHelpers.hasPermissionReadMembers(data),
      readOrganization: userHelpers.hasPermissionReadOrganization(data),
      readRoleAssignments: userHelpers.hasPermissionReadRoleAssignments(data),
      createRoleAssignments:
        userHelpers.hasPermissionCreateRoleAssignments(data),
      deleteRoleAssignments:
        userHelpers.hasPermissionDeleteRoleAssignments(data),
    }),
  });
}

export function useProfileMetadata() {
  return useQuery({
    queryKey: queryKeys.user.profileMetadata,
    queryFn: () => cloudProfileApi.getProfileMetadata(),
  });
}

const verifyAndGetAccountDetails = async (auth0: Auth0ContextInterface) => {
  const user = auth0.user;

  if (!user) {
    throw new Error('User not found');
  }

  if (user.email_verified === false) {
    throw new Error(
      `Email not verified. Please verify "${user.email ?? 'N/A'}"`
    );
  }

  const claims = await auth0.getIdTokenClaims();

  if (!claims) {
    throw new Error('Not able to get claims');
  }

  const accessToken = await auth0.getAccessTokenSilently();

  if (!accessToken) {
    throw new Error('Couldnt get access token');
  }

  return {
    claims,
    user,
    accessToken,
  };
};

const identityProviderToConnectionMap = {
  auth0: 'Username-Password-Authentication',
  'google-oauth2': 'google-oauth2',
  apple: 'apple',
  facebook: 'facebook',
};

export type EnterprisePermission =
  | 'access:enterprise_beta'
  | 'create:invitations'
  | 'create:role_assignments'
  | 'delete:role_assignments'
  | 'read:invitations'
  | 'read:members'
  | 'read:organization_settings'
  | 'read:organization'
  | 'read:role_assignments'
  | 'read:subscription_payment_settings'
  | 'transfer:ownership'
  | 'update:organization_settings';

export interface RmClaims {
  /** User created date */
  'https://auth.remarkable.com/created_at'?: string;

  /** User email address */
  'https://auth.remarkable.com/email'?: string;

  /** User email verified status */
  'https://auth.remarkable.com/email_verified'?: boolean;

  /** Data storage region */
  'https://auth.remarkable.com/tectonic'?: string;

  /** User last login, datestring */
  'https://auth.remarkable.com/last_login'?: string;

  /** How many times the user has logged in */
  'https://auth.remarkable.com/logins_count'?: number;

  /** User ID for alternate linkable account from different IDP */
  'https://auth.remarkable.com/linkable'?: string;

  /** User is associated with an organization */
  'https://auth.remarkable.com/organization'?: string;

  /** Email domain claimed */
  'https://auth.remarkable.com/domain_claimed_by_connection'?: string;

  permissions?: EnterprisePermission[];
  iss?: string;
  sub?: string;
  aud?: string[];
  iat?: number;
  exp?: number;
  scope?: string;
  azp?: string;

  /** If set, the user has access to the organization */
  org_id?: string;
}

/**
 * Looks for the accountLinkingToken that is expected to be passet to Auth0 login method.
 * If present, it will validate that the accounts can be linked and calls the /profiles/link
 * endpoint to complete linking.
 */
export function useAccountLinkingListener(options?: { returnUrl?: string }) {
  const hasLinkedAccounts = useHasLinkedAccounts();
  const auth = useAuth0();
  const search = useSearch({ strict: false });

  const completeLinkingProcess = useMutation({
    mutationKey: queryKeys.user.linkAccounts,
    mutationFn: async ({
      accountLinkingToken,
    }: {
      accountLinkingToken: string;
    }) => {
      if (!CONFIG.Auth0ClientID || !CONFIG.Auth0Domain) {
        throw new Error('Missing Auth0 config.');
      }

      const auth0 = await auth0Client;
      const currentAccount = await verifyAndGetAccountDetails(auth0);

      // ---------------------- Select primary -----------------------
      const firstAccountInfo = jwtDecode<RmClaims>(accountLinkingToken);
      const firstAccountIsSaml = userHelpers.isSAMLUser(firstAccountInfo);
      const currentAccountIsSaml = userHelpers.isSAMLUser(currentAccount.user);

      // Check if both accounts are SAML
      if (firstAccountIsSaml && currentAccountIsSaml) {
        throw new Error('Both accounts cannot be SAML accounts.');
      }

      // Check if neither accounts are SAML
      if (!firstAccountIsSaml && !currentAccountIsSaml) {
        throw new Error(
          'At least one of the accounts has to be a SAML account.'
        );
      }

      if (firstAccountIsSaml) {
        await cloudProfileApi.linkProfiles({
          primaryToken: currentAccount.accessToken,
          secondaryToken: accountLinkingToken,
        });
      } else {
        await cloudProfileApi.linkProfiles({
          primaryToken: accountLinkingToken,
          secondaryToken: currentAccount.accessToken,
        });
      }

      // Track that linking was successful
      const currentAccountProvider = userHelpers.getUserAccountProvider(
        currentAccount.user
      );

      const linkableAccountProvider =
        userHelpers.getUserAccountProvider(firstAccountInfo);

      tracker.trackEvent(
        new AccountLinkingSucceeded({
          component_location: ComponentLocations.ACCOUNT.MIGRATE_CONFIRM,
          account_linking_flow: currentAccountIsSaml
            ? 'Legacy first'
            : 'SSO first',
          primary_account_provider: currentAccountIsSaml
            ? linkableAccountProvider
            : currentAccountProvider,
        })
      );

      const connection =
        userHelpers.getOrganizationConnection(currentAccount.user) ??
        userHelpers.getDomainClaimedConnection(currentAccount.user) ??
        userHelpers.getOrganizationConnection(firstAccountInfo) ??
        userHelpers.getDomainClaimedConnection(firstAccountInfo);

      const organization =
        userHelpers.getOrganizationId(currentAccount.user) ??
        userHelpers.getLoggedInOrganizationId(currentAccount.user) ??
        userHelpers.getOrganizationId(currentAccount.claims) ??
        userHelpers.getLoggedInOrganizationId(currentAccount.claims) ??
        userHelpers.getOrganizationId(firstAccountInfo) ??
        userHelpers.getLoggedInOrganizationId(firstAccountInfo) ??
        (IS_STAGING || IS_DEVELOPMENT ? undefined : connection); // This is just temporary until we can confirm that it works as intended in Staging

      // Login again to get updated account info
      await auth0.loginWithRedirect({
        authorizationParams: {
          // TODO: Find a way to enable prompt: 'none' for legacy first account linking that is not through enterprise enrolment flow
          // prompt: currentAccountIsSaml ? 'none' : undefined,
          login_hint: userHelpers.getEmail(currentAccount.user),
          redirect_uri: window.location.origin,
          organization,
          connection,
        },
        appState: {
          returnTo: sanitizeRedirectUrl(
            options?.returnUrl ?? window.location.pathname
          ),
        },
      });

      return new Promise(() => {
        // Dont resolve so that loading stays until the redirect happens.
      });
    },
    onError(error, variables) {
      toast.error("Couldn't complete account linking. " + error.message);

      Sentry.withScope((scope) => {
        scope.captureMessage("Couldn't complete account linking.");
        Sentry.captureException(error);
      });

      if (!auth.user) {
        // This shouldn't be possible, but TS wants it anyway.
        throw new Error(
          'User not available when handling account linking listener error.'
        );
      }

      const currentAccountProvider = userHelpers.getUserAccountProvider(
        auth.user
      );
      const isCurrentUserSAML = userHelpers.isSAMLUser(auth.user);
      const linkableAccountFromToken = jwtDecode<RmClaims>(
        variables.accountLinkingToken
      );
      const linkableAccountProvider = userHelpers.getUserAccountProvider(
        linkableAccountFromToken
      );

      tracker.trackEvent(
        new AccountLinkingFailed({
          component_location: ComponentLocations.ACCOUNT.MIGRATE_CONFIRM,
          account_linking_flow: isCurrentUserSAML
            ? 'Legacy first'
            : 'SSO first',
          primary_account_provider: isCurrentUserSAML
            ? linkableAccountProvider
            : currentAccountProvider,
          error_message: error.message,
        })
      );
    },
  });

  useEffect(() => {
    if (typeof search.accountLinkingToken !== 'string') return;

    if (!hasLinkedAccounts.isSuccess) return;

    // Check if already linked
    if (hasLinkedAccounts.data.isLinked) {
      console.warn('Aborting account linking, account is already linked.');
      return;
    }

    completeLinkingProcess.mutate({
      accountLinkingToken: search.accountLinkingToken,
    });
  }, [hasLinkedAccounts.isSuccess, search.accountLinkingToken]);

  return completeLinkingProcess;
}

export function useLoginToLinkableAccount() {
  const auth0 = useAuth0();

  const startLinking = useMutation({
    mutationKey: queryKeys.user.startLinkAccounts,
    mutationFn: async (
      options: {
        connection?: string;
        startLinking?: boolean;
        redirectUrl?: string;
      } = {
        startLinking: false,
      }
    ) => {
      if (!CONFIG.Auth0ClientID || !CONFIG.Auth0Domain) {
        throw new Error('Missing auth0 config');
      }

      const currentAccount = await verifyAndGetAccountDetails(auth0);

      let connection =
        options?.connection ??
        userHelpers.getDomainClaimedConnection(currentAccount.claims);
      let isLegacyTarget = false;

      if (!connection) {
        if (userHelpers.isLinkable(currentAccount.claims) !== true) {
          throw new Error('Account is not linkable');
        }

        const linkableAccountId: string | null =
          userHelpers.getLinkableUserId(currentAccount.claims) ?? null;

        // For legacy users we can have a mapping between account id and connection
        const accountIdentityProvider = linkableAccountId?.split('|')[0];

        if (!accountIdentityProvider) {
          throw new Error(
            'Unable to get identity provider from linkable account ID.'
          );
        }

        connection = (
          identityProviderToConnectionMap as Record<string, string>
        )[accountIdentityProvider];
        isLegacyTarget = true;
      }

      if (!connection) {
        throw new Error('Unable to find connection to linkable account.');
      }

      const organization = userHelpers.getOrganizationId(currentAccount.claims);

      const returnUrl = new URL(
        options.redirectUrl ?? window.location.pathname,
        window.location.origin
      );

      if (options.startLinking) {
        returnUrl.searchParams.set(
          'accountLinkingToken',
          currentAccount.accessToken
        );
      }

      await auth0.loginWithRedirect({
        authorizationParams: {
          max_age: 0,
          scope: 'openid',
          connection,
          organization:
            IS_STAGING || IS_DEVELOPMENT // This is just temporary until we can confirm that it works as intended in Staging
              ? undefined
              : isLegacyTarget
              ? undefined
              : organization, // legacy users can't login to org
          login_hint: currentAccount.user.email,
          redirect_uri: window.location.origin,
        },
        appState: {
          returnTo: sanitizeRedirectUrl(
            returnUrl.pathname + returnUrl.search + returnUrl.hash
          ),
        },
      });
    },
    onError(error, variables) {
      Sentry.captureException(error, {
        tags: {
          context: 'Failed during login to linkable account.',
          start_linking: !!variables?.startLinking,
          redirect_url: variables?.redirectUrl,
        },
      });
    },
  });

  return startLinking;
}
