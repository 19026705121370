import { useLocation } from '@tanstack/react-router';

import {
  CheckoutType,
  CheckoutTypeParam,
} from 'src/api/endpoints/storeApi.types';
import { URLS } from 'src/utils/urls/urls';

const checkoutTypeMap: Record<CheckoutTypeParam, CheckoutType> = {
  [CheckoutTypeParam.CONNECT_OFFER]: 'connectOffer',
  [CheckoutTypeParam.STORE]: 'store',
  [CheckoutTypeParam.DM_OFFER]: 'dmOffer',
};
export const mapCheckoutTypeToRouteType: Record<
  CheckoutType,
  CheckoutTypeParam
> = {
  store: CheckoutTypeParam.STORE,
  connectOffer: CheckoutTypeParam.CONNECT_OFFER,
  dmOffer: CheckoutTypeParam.DM_OFFER,
} as const;

function pathEqualsOrStartsWith(actualPath: string) {
  return (partial: string) => {
    const pathToMatch = partial.replace(/\/$/, ''); // remove trailing slash for consistency

    if (actualPath.startsWith(pathToMatch + '/')) {
      return true;
    }

    return actualPath === pathToMatch;
  };
}

export const useCheckoutType = (): CheckoutType | null => {
  const { pathname } = useLocation();

  const pathMatches = pathEqualsOrStartsWith(pathname);

  if (pathMatches(URLS.STORE_CONNECT_OFFER_LANDING)) {
    return checkoutTypeMap['connect-offer'];
  }

  if (pathMatches(URLS.STORE_DM_OFFER_LANDING)) {
    return checkoutTypeMap['dm-offer'];
  }

  if (pathMatches(URLS.STORE_CONNECT_LANDING)) {
    return checkoutTypeMap['store'];
  }

  return null;
};

export const getCheckoutUrl = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case 'connectOffer':
      return URLS.STORE_CONNECT_OFFER_CHECKOUT;
    case 'dmOffer':
      return URLS.STORE_DM_OFFER_CHECKOUT;
    default:
      return URLS.STORE_CONNECT_CHECKOUT;
  }
};
