import {
  StripeSubscriptionWithPaymentMethod,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

import { subscriptionHelpers } from './subscriptionHelpers';

export const subscriptionHasPaymentMethod = (
  subscription?: Subscription | null
): subscription is StripeSubscriptionWithPaymentMethod => {
  if (subscriptionHelpers.isStripe(subscription)) {
    if (
      subscription.paymentInformation &&
      subscription.paymentInformation.payment
    )
      return subscription.paymentInformation.payment.type === 'card'
        ? !!subscription.paymentInformation.payment.details
        : true;
  }
  return false;
};
