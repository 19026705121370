import { useCart } from 'src/api/queries';
import { auth0Client } from 'src/auth0Client';
import { StripeIntentType, useStripe } from 'src/utils/stripe';

import { useCheckoutType } from '../useCheckoutType';
import { CheckoutFormState } from './Checkout.types';
import { getFormError } from './getFormError';

export const useCheckout = () => {
  const checkoutType = useCheckoutType();
  const cart = useCart();

  const stripeIntentType: StripeIntentType =
    checkoutType === 'store'
      ? StripeIntentType.Payment
      : StripeIntentType.Setup;
  const stripeCheckout = useStripe(stripeIntentType);

  const submit = (formState: CheckoutFormState) => {
    cart.checkout.mutate(
      {
        billing_address: {
          first_name: formState.firstName ?? '',
          last_name: formState.lastName ?? '',
          // Company name is optional
          company_name: formState.companyName || undefined,
          line_1: formState.billingAddress1 ?? '',
          line_2: formState.billingAddress2 ?? '',
          city: formState.city ?? '',
          county: formState.state ?? '',
          postcode: formState.postalCode ?? '',
          country: formState.country ?? '',
          VAT: formState.VAT,
        },
      },
      {
        onSuccess: async () => {
          const auth0 = await auth0Client;
          const email = auth0.user?.email;

          const confirmationPagePath = window.location.pathname.replace(
            '/checkout',
            '/confirmation'
          );

          return await stripeCheckout.checkout.mutateAsync({
            mandate_data: JSON.parse(
              atob(cart.setup.data?.encodedMandateData ?? '') || '{}'
            ) as Record<string, unknown>,
            return_url: `${window.location.origin}${confirmationPagePath}`,
            payment_method_data: {
              billing_details: {
                name: [formState.firstName, formState.lastName]
                  .filter(Boolean) // remove nullish values
                  .join(' '),
                email: email,
                address: {
                  line1: formState.billingAddress1 ?? '',
                  line2: formState.billingAddress2 ?? '',
                  city: formState.city ?? '',
                  state: formState.state ?? '',
                  country: formState.country ?? '',
                  postal_code: formState.postalCode ?? '',
                },
                phone: '',
              },
            },
          });
        },
        onError: (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
      }
    );
  };

  return {
    submit,
    isPending: cart.checkout.isPending || stripeCheckout.checkout.isPending,
    isSuccess: cart.checkout.isSuccess && stripeCheckout.checkout.isSuccess,
    error: getFormError(cart.checkout, stripeCheckout.checkout),
  };
};
