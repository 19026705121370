import { ReactNode } from 'react';

import { components } from 'apis/rm-store-api-public';

import { SKU } from 'src/utils/skus';

// Account ---------------------------------------------------------------

export type AccountAddressResponse =
  components['schemas']['MyRmAddressResponse'];
export type AccountResponse = components['schemas']['MyRmGetAccountResponse'];

// Subscription ---------------------------------------------------------------

export type SubscriptionsResponse =
  components['schemas']['MyRmGetSubscriptionsResponse'];

export type Subscription =
  components['schemas']['MyRmGetSubscriptionsResponse']['data'];

export const SubscriptionStatus = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Deactivated: 'Deactivated',
  NoSubscription: 'None',
} as const;

export type SubscriptionStatus =
  (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus];

export type StripeSubscription =
  components['schemas']['MyRmGetSubscriptionsStripeSubscription'];

export type FreeConnectSubscription =
  components['schemas']['MyRmGetSubscriptionsFreeConnectSubscription'];

export type SubscriptionStripeStatus =
  components['schemas']['MyRmGetSubscriptionsStripeSubscription']['status'];

export type SubscriptionManagementType =
  components['schemas']['MyRmGetSubscriptionsStripeSubscription']['managementType'];

export type SubscriptionPaymentInformation =
  components['schemas']['MyRmGetSubscriptionsNotCancelledPaymentInformation'];

export interface StripeSubscriptionWithPaymentMethod
  extends StripeSubscription {
  paymentInformation: SubscriptionPaymentInformationNotNull;
}

interface SubscriptionPaymentInformationNotNull
  extends SubscriptionPaymentInformation {
  payment: SubscriptionPaymentNotNull;
}

type SubscriptionPaymentNotNull = SubscriptionPaymentMethodNotNull & {
  details: SubscriptionPaymentDetailsNotNull;
};

type SubscriptionPaymentMethodNotNull = NonNullable<
  SubscriptionPaymentInformation['payment']
>;

type SubscriptionPaymentDetailsNotNull = NonNullable<
  SubscriptionPaymentMethodNotNull['details']
>;

export type SubscriptionPaymentMethodType =
  SubscriptionPaymentMethodNotNull['type'];

export type SubscriptionCanceledPaymentInformation =
  components['schemas']['MyRmGetSubscriptionsCancelledPaymentInformation'];

export interface StripeSubscriptionCancelled extends StripeSubscription {
  paymentInformation: SubscriptionCanceledPaymentInformation;
}

export interface StripeSubscriptionDeactivated extends StripeSubscription {
  paymentInformation: components['schemas']['MyRmGetSubscriptionsDeactivatedPaymentInformation'];
}

export type SubscriptionDeactivatedPaymentInformation =
  components['schemas']['MyRmGetSubscriptionsDeactivatedPaymentInformation'];

export type SubscriptionPaymentInterval = NonNullable<
  SubscriptionPaymentInformation['paymentInterval']
>;

export type SubscriptionPaymentIntervalOption = NonNullable<
  SubscriptionPaymentInformation['paymentIntervalOptions']
>[number];

export type SubscriptionMembersResponse =
  components['schemas']['MyRmGetSubscriptionMembersResponse']['data'];

export type SubscriptionMember =
  components['schemas']['MyRmGetSubscriptionMembersMember'];

export type SubscriptionRole = components['schemas']['SubscriptionRoleEnum'];

// Seats
export type SubscriptionMemberSeat =
  components['schemas']['MyRmGetSubscriptionMembersMemberSeat'];

export type SubscriptionInvitationResponse =
  components['schemas']['MyRmGetSubscriptionInvitationResponse'];

// Feedback -------------------------------------------------------------------

export type FeedbackTargetValues =
  components['parameters']['FeedbackTargetValues'];

// Checkout -------------------------------------------------------------------

export type CheckoutAllowedResponse =
  components['schemas']['MyRmGetAllowedFlowsResponse'];

// Other ----------------------------------------------------------------------
// TODO: Cleanup the rest of these types as we go, create more aliases to the generated
// types in apis/rm-store-api-public.ts and remove the ones that are not used.

export interface ApiProviderProps {
  children: ReactNode;
  withDevtools?: boolean;
}

export enum CheckoutTypeParam {
  CONNECT_OFFER = 'connect-offer',
  STORE = 'store',
  DM_OFFER = 'dm-offer',
}

export type CheckoutType = keyof components['schemas']['MyRmAllowedFlows'];

export type BillingInfoAddress = {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  zip?: string;
  state?: string;
};

export type BillingInfoForm = {
  name?: string;
  companyName?: string | null;
  billingType?: string;
  vatNumber?: string | null;
  email?: string | null;
  address: BillingInfoAddress;
};

export interface BillingInfoAllowedResponse {
  data?: BillingInfoForm;
}

export type AddressFormType = {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  zip?: string;
  state?: string;
};
export type BillingFormType = {
  name?: string;
  companyName?: string | null;
  billingType?: string;
  vatNumber?: string | null;
  address: AddressFormType;
};

export interface CartDetailsResponse {
  data: {
    currency: string;
    billingDate: string;

    product: {
      sku: string;
      name: string;
    };
    productPrice: {
      base: number;
      total: number;
      tax: number;
    };
    cartPrice: {
      base: number;
      total: number;
      tax: number;
    };
    tax: {
      isCalculated: boolean;
      isIncluded: boolean;
      percentage: number;
    };
  };
}

export interface CheckoutCartTaxBody {
  country: string;
  county: string;
  city?: string;
  zip: string;
}

export interface SetupResponse {
  data: {
    clientSecret: string;
    encodedMandateData: string;
    currency: string;
    cartId: string;
    billingDate: string;
    product: {
      sku: string;
      name: string;
    };
    productPrice: {
      base: number;
      total: number;
      tax: number;
    };
    cartPrice: {
      base: number;
      total: number;
      tax: number;
    };
    tax: {
      isCalculated: boolean;
      isIncluded: boolean;
      percentage: number;
    };
  };
}
export interface CheckoutsCheckoutResponse {
  data: {
    cartId: string;
  };
}

export interface CheckoutsVerifyIntentResponse {
  data: {
    orderId: string;
    currency: string;
    billingDate: string;

    billingAddress: {
      country: string;
      county?: string;
    };

    product: {
      sku: string;
      name: string;
    };
    productPrice: {
      base: number;
      total: number;
      tax: number;
    };
    cartPrice: {
      base: number;
      total: number;
      tax: number;
    };
    tax: {
      isCalculated: boolean;
      isIncluded: boolean;
      percentage: number;
    };
  };
}

export type InvoiceCheckoutRequestBody =
  components['schemas']['MyRmCheckoutWithoutIntentRequestBody'];
export type InvoiceCheckoutResponseBody =
  components['schemas']['MyRmCheckoutInvoiceResponse'];

export type PaymentInterval =
  components['schemas']['MyRmGetSubscriptionsPaymentInformationPaymentOptions']['interval'];
export const PaymentInterval = {
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
} as const satisfies Record<Uppercase<PaymentInterval>, PaymentInterval>;

export interface ChangeEmailResponse {
  data: ChangeEmailInfo;
}

export interface RequestEmailChangeError {
  context: {
    userMessage: string;
  };
  error: string;
  ok: boolean;
  statusCode: number;
}

export class InvalidEmailException extends Error {
  constructor(public message: string) {
    super(message);
  }
}

export interface ChangeEmailInfo {
  auth0UserId: string;
  createdAt: string;
  expiresAt: string;
  newEmail: string;
  oldEmail: string;
  status: string;
  token: string;
  updatedAt: string;
}

export interface UpdateEmailBody {
  newEmail: string;
  currentEmail: string;
}

export interface AccountInfoResponse {
  data: AccountInfo;
}
export interface AccountInfo {
  mfaEnrolled: boolean;
  mfa_enrolled: boolean; // Legacy property
  firstName: string;
  lastName: string;
  identities: Identity[];
}

export interface Identity {
  connection: string;
  user_id: string;
  provider: string;
  isSocial: boolean;
}

// ========================================================
// Products
// ========================================================

export interface ProductPricesResponse {
  data: Price[];
}

export interface Price {
  amount: number;
  currency: string;
  includes_tax: boolean;
}

export interface CreateSetupIntentResponse {
  data: {
    clientSecret: string;
    name: string | null;
    email: string | null;
    phone: string | null;
    address: {
      city: string | null;
      country: string | null;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    } | null;
  };
}

export interface VerifySetupIntentResponse {
  data: {
    success: boolean;
  };
}

export interface Payment {
  createdAt: string;
  invoiceDueDate: string;
  amount: {
    total: number;
    currency: string;
  };
  sku: typeof SKU;
  paymentInterval: PaymentInterval;
  managementType: SubscriptionManagementType;
  seats: number | null;
  receiptUrl: string | null;
  paid: boolean;
  refund: boolean;
  invoicePdf: string | null;
}

export interface PaymentHistoryResponse {
  data: Payment[];
}

// ========================================================
// Offers
// ========================================================

export type ProcessedActivationResponse =
  components['schemas']['MyRmDeviceActivationResponse'];

// ========================================================
// Subscriptions V2 Members
// ========================================================

// Copied from rm-store-api (/src/handlers/myrm/subscription/myRmSubscriptionMembersHandlers/types.ts)
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MyRmSubscriptionMembersHandlersTypes {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace ListMembers {
    export interface Member {
      id: string;
      createdAt: string;
      updatedAt: string | null;
      role: string;
      email: string | null;
      isCurrentUser: boolean;
      seat: Seat | null;
      access: Access;
    }

    export interface Seat {
      id: string;
      createdAt: string;
      updatedAt: string | null;
      status: 'created' | 'pending';
      pendingUntil: string | null;
    }

    export interface Access {
      canGiveConnect: boolean;
      canRemoveConnect: boolean;
      canRemoveUser: boolean;
    }

    export type Response = APIJsonMessage<{
      data: Member[];
    }>;
  }
}

// ========================================================
// Invitations
// ========================================================
type SeatInvitationBase =
  components['schemas']['MyRmGetSubscriptionInvitation'];

interface SeatInvitationDeclined {
  isDeclined: true;
  isExpired: false;
  declinedAt: Date;
}

interface SeatInvitationNotDeclined {
  isDeclined: false;
  isExpired: boolean;
}

export type SeatInvitation = SeatInvitationBase &
  (SeatInvitationDeclined | SeatInvitationNotDeclined);

export interface SeatInvitationsResponse {
  data: SeatInvitation[];
}

// ========================================================

type APIJsonMessage<T> = T;

export enum SubscriptionManagementTypeNotAllowedReason {
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  HAS_FREE_CONNECT = 'HAS_FREE_CONNECT',
  SUBSCRIPTION_SET_TO_CANCEL = 'SUBSCRIPTION_SET_TO_CANCEL',
  SUBSCRIPTION_SET_TO_CHANGE_PAYMENT_INTERVAL = 'SUBSCRIPTION_SET_TO_CHANGE_PAYMENT_INTERVAL',
  SUBSCRIPTION_IS_TRAILING = 'SUBSCRIPTION_IS_TRAILING',
}

export type GetSubscriptionManagementTypeResponse =
  | {
      data: {
        allowed: true;
        context?: {
          hasFreeConnect?: true;
          refund?: { amount: number; currency: string };
        };
      };
    }
  | {
      data: {
        allowed: false;
        reason: SubscriptionManagementTypeNotAllowedReason;
      };
    };

export type CreateSeatInvitationLinkResponse = {
  data: {
    invitationLink: string;
  };
};

export type GetFeatureFlagSubsolarCanaryResponse = {
  data: {
    allowed: boolean;
  };
};

// ========================================================
// Stripe test clock
// ========================================================

export interface StripeTestClockResponse {
  data:
    | {
        exists: false;
      }
    | {
        exists: true;
        url: string;
        frozenTime: string;
        name: string;
      };
}

export interface StripeTestClockErrorResponse {
  statusCode: number;
  error: string;
  ok: false;
  context: unknown;
}
