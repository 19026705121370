import { tracker } from 'src/analytics/tracker';

import { ExperimentRecord, Variant, Experiments } from './config';

export const getExperiments = (): ExperimentRecord | null => {
  if (Object.keys(Experiments).length === 0) {
    return null;
  }
  return Experiments as ExperimentRecord;
};

async function sha256(message: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

// Hash the user ID to get a consistent "random" value between 0 and 1
const hashString = async (input: string): Promise<number> => {
  const hash = await sha256(input);
  return parseInt(hash.slice(-8), 16) / 0xffffffff;
};

const validateWeights = (variants: Variant[]) => {
  const totalWeight = variants.reduce(
    (sum, variant) => sum + variant.weight,
    0
  );
  if (totalWeight !== 1) {
    throw new Error('Total weight of variants must be 1.');
  }
};

// Get the random variant based on the user ID
export const getRandomVariant = async (
  variants: Variant[],
  userId: string
): Promise<Variant> => {
  if (variants.length === 0) {
    throw new Error('No variants provided.');
  }

  validateWeights(variants);

  const random = await hashString(userId); // Generate a consistent random value
  let sum = 0;
  for (const variant of variants) {
    sum += variant.weight;
    if (random <= sum) {
      return variant;
    }
  }

  return variants[variants.length - 1]; // Fallback to the last variant
};

export const findAppliedVariant = (
  id: string,
  variants: Variant[]
): Variant | null => {
  const appliedExperiments = tracker.getExperiments();
  const appliedExperiment = appliedExperiments?.find((exp) => exp.id === id);
  if (!appliedExperiment) {
    return null;
  }
  return (
    variants.find(
      (variant) => variant.variantName === appliedExperiment.variant
    ) ?? null
  );
};
