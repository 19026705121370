import { useAuth0 } from '@auth0/auth0-react';
import {
  createFileRoute,
  Navigate,
  redirect,
  useSearch,
} from '@tanstack/react-router';

import { AccountMigrate } from 'src/apps/account/app/pages/AccountMigrate';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_public/_layout/account/migrate/saml')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user || !auth.isAuthenticated) {
      throw redirect({
        to: '/login',
        statusCode: 401,
        search: {
          redirect: location.href,
        },
      });
    }

    const isLinking = location.searchStr.includes('accountLinkingToken');

    if (isLinking) {
      return;
    }

    if (!userHelpers.isSAMLUser(auth.user)) {
      throw redirect({
        to: userHelpers.isDomainClaimedByConnection(auth.user)
          ? '/account/migrate'
          : '/',
      });
    }

    if (!userHelpers.isAccountRestrictedToAccountLinking(auth.user)) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: () => {
    const auth0 = useAuth0();
    const search = useSearch({ strict: false });

    if (!auth0.user) {
      // This is really handled in the beforeLoad handler, but TS.
      return <Navigate to="/login" replace />;
    }

    if (!userHelpers.isSAMLUser(auth0.user) && !search.accountLinkingToken) {
      return <Navigate to="/" replace />;
    }

    return <AccountMigrate />;
  },
});
