/* eslint-disable tailwindcss/no-custom-classname */
import { HTMLAttributes, useEffect } from 'react';

import { ArrowRight } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useLocation } from '@tanstack/react-router';
import { CheckCircle } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useSubscription } from 'src/api/queries';
import { useAuth0Claims } from 'src/api/queries/user';
import { ChangeCreditCardButton, Link } from 'src/components';
import { useNotifications } from 'src/hooks/useNotifications';
import { cn } from 'src/utils/classNamesHelper';
import { formatDate } from 'src/utils/formatDate';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { subscriptionTrialWillCancel } from 'src/utils/subscriptionTrialWillCancel';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

import { Notification } from './Notification';

export const HeaderNotification = () => {
  return <HeaderNotificationContent />;
};

function NotificationContainer({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('mx-auto mt-24 max-w-lxl px-16 ls:px-32', className)}
      {...props}
    />
  );
}

const HeaderNotificationContent = () => {
  const location = useLocation();
  const claims = useAuth0Claims();
  const notifications = useNotifications();
  const subscription = useSubscription();

  useEffect(() => {
    if (!claims.data) return;

    if (
      !userHelpers.isLinkable(claims.data) &&
      !userHelpers.isDomainClaimedByConnection(claims.data)
    ) {
      notifications.remove('EMAIL_DOMAIN_CLAIMED');
      return;
    }

    notifications.set({ id: 'EMAIL_DOMAIN_CLAIMED' }, { force: true });
  }, [claims.data, location]);

  // Remove subscription notifications when user navigates away from subscription page
  useEffect(() => {
    if (!location.pathname.startsWith(URLS.SUBSCRIPTION)) {
      switch (notifications.active[0]?.id) {
        case 'JOINED_CONNECT_SEATS_AS_MEMBER':
          notifications.remove('JOINED_CONNECT_SEATS_AS_MEMBER');
          break;
        case 'JOINED_CONNECT_SEATS_AS_MEMBER_WITH_REFUND':
          notifications.remove('JOINED_CONNECT_SEATS_AS_MEMBER_WITH_REFUND');
          break;
        case 'JOINED_CONNECT_SEATS_AS_OWNER':
          notifications.remove('JOINED_CONNECT_SEATS_AS_OWNER');
          break;
        case 'JOINED_CONNECT_SEATS_AS_OWNER_WITH_REFUND':
          notifications.remove('JOINED_CONNECT_SEATS_AS_OWNER_WITH_REFUND');
          break;
      }
    }
  }, [location.pathname]);

  switch (notifications.active[0]?.id) {
    case 'EMAIL_DOMAIN_CLAIMED': {
      if (
        location.pathname.includes(URLS.ENTERPRISE) ||
        location.pathname.includes(URLS.ACCOUNT_MIGRATE)
      ) {
        break;
      }

      return (
        <Link to={URLS.ACCOUNT_MIGRATE} className="w-full no-underline">
          <Notification
            className="justify-center border-none bg-pen-blue py-8 text-white shadow-sheet transition-all hover:bg-pen-blue-dark"
            data-cy="email-domain-claimed"
          >
            <div className="m-auto flex items-center justify-center gap-16">
              <p className="responsive-body-md">
                Start using your enterprise account
              </p>

              <ArrowRight size={32} className="shrink-0 transition-all" />
            </div>
          </Notification>
        </Link>
      );
    }

    case 'JOINED_CONNECT_WITHOUT_CARD':
      if (
        !subscription.data ||
        !subscriptionHelpers.isStripe(subscription.data)
      ) {
        return;
      }
      if (!subscriptionTrialWillCancel(subscription.data)) {
        notifications.remove('JOINED_CONNECT_WITHOUT_CARD');
        return;
      }
      return (
        <NotificationContainer>
          <Notification
            data-cy="joined-connect-without-card-notification"
            onClick={() => notifications.remove('JOINED_CONNECT_WITHOUT_CARD')}
          >
            <div className="mb-24">
              <Typography variant="heading-subtle-md" className="my-0">
                Your Connect trial ends{' '}
                {formatDate(
                  subscription.data.paymentInformation?.nextInvoice?.dueDate
                )}
                . Add a payment method to keep access.
              </Typography>
              <Typography
                variant="interface-sm-regular"
                className="my-0 leading-6"
              >
                Your 30-day free trial ends{' '}
                {formatDate(
                  subscription.data.paymentInformation?.nextInvoice?.dueDate
                )}
                . Add a payment method to keep access to unlimited cloud storage
                and sync, plus other{' '}
                <Link inline to={URLS.RM_COM_STORE_CONNECT}>
                  Connect features
                </Link>
                .
              </Typography>
            </div>
            <ChangeCreditCardButton
              componentLocation={
                ComponentLocations.NOTIFICATIONS.UPDATE_PAYMENT_CARD
              }
            />
          </Notification>
        </NotificationContainer>
      );
    case 'JOINED_CONNECT_SEATS_AS_OWNER':
      return (
        <NotificationContainer>
          <Notification
            data-cy="joined-connect-seats-as-owner-notification"
            onClick={() =>
              notifications.remove('JOINED_CONNECT_SEATS_AS_OWNER')
            }
          >
            <div className="flex">
              <CheckCircle
                className="mr-16 inline-block shrink-0 text-feedback-green-500"
                weight="fill"
                size={32}
              />{' '}
              <p>You can now add users to your subscription.</p>
            </div>
          </Notification>
        </NotificationContainer>
      );

    case 'JOINED_CONNECT_SEATS_AS_OWNER_WITH_REFUND':
      return (
        <NotificationContainer>
          <Notification
            data-cy="joined-connect-seats-as-owner-with-refund-notification"
            onClick={() =>
              notifications.remove('JOINED_CONNECT_SEATS_AS_OWNER_WITH_REFUND')
            }
          >
            <div className="flex">
              <CheckCircle
                className="mr-16 inline-block shrink-0 text-feedback-green-500"
                weight="fill"
                size={32}
              />{' '}
              <p>
                You can now add users to your subscription. The refund for your
                previous subscription is being processed. Go to{' '}
                <Link inline to={URLS.SUBSCRIPTION_PAYMENT}>
                  Payment history
                </Link>{' '}
                to find your receipt.
              </p>
            </div>
          </Notification>
        </NotificationContainer>
      );

    case 'JOINED_CONNECT_SEATS_AS_MEMBER':
      return (
        <NotificationContainer>
          <Notification
            data-cy="joined-connect-seats-as-member-notification"
            onClick={() =>
              notifications.remove('JOINED_CONNECT_SEATS_AS_MEMBER')
            }
          >
            <div className="flex">
              <CheckCircle
                className="mr-16 inline-block shrink-0 text-feedback-green-500"
                weight="fill"
                size={32}
              />{' '}
              <p>You&apos;ve successfully joined Connect</p>
            </div>
          </Notification>
        </NotificationContainer>
      );

    case 'JOINED_CONNECT_SEATS_AS_MEMBER_WITH_REFUND':
      return (
        <NotificationContainer>
          <Notification
            data-cy="joined-connect-seats-as-member-with-refund-notification"
            onClick={() =>
              notifications.remove('JOINED_CONNECT_SEATS_AS_MEMBER_WITH_REFUND')
            }
          >
            <div className="flex">
              <CheckCircle
                className="mr-16 inline-block shrink-0 text-feedback-green-500"
                weight="fill"
                size={32}
              />{' '}
              <p>
                You&apos;ve successfully joined Connect. The refund for your
                previous subscription is being processed. Go to{' '}
                <Link inline to={URLS.SUBSCRIPTION_PAYMENT}>
                  Payment history
                </Link>{' '}
                to find your receipt.
              </p>
            </div>
          </Notification>
        </NotificationContainer>
      );

    default:
      return null;
  }
};
