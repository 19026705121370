import { FC, useMemo } from 'react';

import { RMpp } from '@remarkable/ark-icons';
import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { Plus } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useDeleteDevice, useDevices, useSubscription } from 'src/api/queries';
import {
  Button,
  CardContent,
  Modal,
  NotificationBox,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { Link2 } from 'src/components/Link2';
import { URLS } from 'src/utils/urls/urls';

import { MAXIMUM_DEVICES_ALLOWED } from '../utils/constants';
import { useMaxDevicesError } from '../utils/useMaxDevicesError';
import { filterActivePaperTabletClients } from '../utils/utils';
import { DeviceTable } from './DeviceTable';
import { OneTimeCode } from './OneTimeCode';
import { VerifyMultiplePairModal } from './VerifyMultiplePairModal';

const PairingText: FC<{
  hasSubscription: boolean;
  activeDevices: number;
}> = (props) => {
  const pairingLimitReached = props.activeDevices >= MAXIMUM_DEVICES_ALLOWED;

  if (pairingLimitReached) {
    return (
      <div className="w-full lm:w-2/3 ">
        <Typography variant="body-md-regular">
          You&apos;ve reached the limit of paired devices. Unpair one of your
          paper tablets before pairing a new one.
        </Typography>
      </div>
    );
  }

  return (
    <div className="w-full lm:w-2/3 ">
      <Typography variant="body-md-regular">
        Pair your account with up to {MAXIMUM_DEVICES_ALLOWED} paper tablets.
        Once paired, you can access notes on mobile and desktop apps.
      </Typography>
    </div>
  );
};

const routeApi = getRouteApi('/_auth/_layout/device');

export const Remarkable = () => {
  const { showOtp } = routeApi.useSearch();
  const connectedDevices = useDevices();

  const deleteDeviceMutation = useDeleteDevice();
  const [hasMaxDevicesError, setMaxDevicesError] = useMaxDevicesError();

  const subscription = useSubscription();
  const hasSubscription = !!subscription.data;

  const activeDevices = useMemo(
    () => filterActivePaperTabletClients(connectedDevices.data),
    [connectedDevices.data]
  );

  const hasActiveDevice = activeDevices.length > 0;
  const canConnectMoreDevices = activeDevices.length < MAXIMUM_DEVICES_ALLOWED;

  const cardTitle = activeDevices.length > 1 ? 'Paper tablets' : 'Paper tablet';

  if (
    connectedDevices.isPending ||
    deleteDeviceMutation.isPending ||
    subscription.isPending
  ) {
    return <Spinner />;
  }

  if (
    connectedDevices.isError ||
    deleteDeviceMutation.isError ||
    subscription.isError
  ) {
    return <SomethingWentWrong />;
  }
  if (showOtp) {
    return (
      <Sheet>
        <CardContent>
          <OneTimeCode
            successRedirectUrl={URLS.DEVICE_REMARKABLE}
            description="Enter this one-time code on your paper tablet, and tap enter."
            allowPairing={!canConnectMoreDevices}
            hideCopyToClipboard // Cannot copy from browser to tablet
            componentLocation={ComponentLocations.DEVICE.DEVICE}
          />
        </CardContent>
      </Sheet>
    );
  }

  return (
    <div className="flex flex-col gap-16 lm:gap-24">
      {hasMaxDevicesError && (
        <NotificationBox
          onClose={() => {
            setMaxDevicesError(false);
          }}
          variant="warning"
          title="Maximum devices already paired"
        >
          Unpair existing devices in order to pair new ones.
        </NotificationBox>
      )}
      <Sheet>
        <Modal.Root>
          <VerifyMultiplePairModal />
          <CardContent className="my-12 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <RMpp size={32} className="mr-16" />
              <Typography variant="heading-subtle-2xl" as="h2">
                {cardTitle}
              </Typography>
            </div>
          </CardContent>

          <CardContent borderTop className="flex flex-col space-y-20 pb-0">
            <div className="flex flex-col justify-between gap-20 text-left lm:mt-8 lm:flex-row">
              <PairingText
                hasSubscription={hasSubscription}
                activeDevices={activeDevices.length}
              />

              <div>
                {hasActiveDevice ? (
                  <Modal.Trigger>
                    <Button
                      data-cy="pair-device-button"
                      variant="tertiary"
                      className="w-full lm:w-auto"
                      disabled={!canConnectMoreDevices}
                      size="medium"
                    >
                      <Plus size={24} />
                      <span>Pair device</span>
                    </Button>
                  </Modal.Trigger>
                ) : (
                  <Link2
                    data-cy="pair-device-button"
                    search={{ showOtp: true }}
                    size="medium"
                    as="button"
                    variant="primary"
                  >
                    <Plus size={24} />
                    <span>Pair device</span>
                  </Link2>
                )}
              </div>
            </div>

            {!hasSubscription && activeDevices.length > 1 && (
              <NotificationBox
                title="Cloud limit reached"
                variant="info"
                data-cy="multiple-devices-cloud-warning"
              >
                <Typography variant="body-md-regular" className="mb-0">
                  Without Connect, documents stop syncing after 50 days of
                  inactivity. Reactivate cloud storage and sync documents across
                  all devices by subscribing.
                  <Button
                    as="a"
                    to={URLS.SUBSCRIPTION}
                    variant="tertiary"
                    className="mt-12"
                    size="small"
                  >
                    Subscribe
                  </Button>
                </Typography>
              </NotificationBox>
            )}
          </CardContent>
        </Modal.Root>
      </Sheet>
      <DeviceTable deviceType={'paper-tablet'} />
    </div>
  );
};
