import { useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Appearance, ConfirmPaymentData } from '@stripe/stripe-js';
import { UseMutationResult } from '@tanstack/react-query';
import { useLocation } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { CreateSetupIntentResponse } from 'src/api/endpoints/storeApi.types';
import { Button, Link, Modal, NotificationBox } from 'src/components';
import { SearchParam } from 'src/utils/searchParam';
import {
  CustomStripeError,
  StripeIntentType,
  useStripe,
} from 'src/utils/stripe';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { Link2 } from './Link2';
import { LinkExternal } from './LinkExternal';

const getStripeError = (
  checkout: UseMutationResult<unknown, Error, ConfirmPaymentData, unknown>
) => {
  const { error } = checkout;
  if (error === null) {
    return null;
  }

  if (error instanceof CustomStripeError) {
    if (error.data.type === 'validation_error') {
      // Do not render this error box, as the Stripe element will already indicate that the customer must fix to proceed
      return null;
    }

    if (
      error.data.type === 'invalid_request_error' &&
      error.data.code === 'parameter_invalid_empty'
    ) {
      // This error can be thrown if we do not have the correct customer information required. We ask the customer to navigate
      // to Account to fill in their account information instead of making yet another checkout form.
      return {
        title: 'Unable to update payment method',
        message: (
          <>
            Some of your payment details are missing. Please go to{' '}
            <Link2 inline to="/account">
              Account
            </Link2>
            , and enter your name and country, or{' '}
            <LinkExternal
              inline
              to={SUPPORT_URL.HOME}
              target="_blank"
              rel="noreferrer"
            >
              contact support
            </LinkExternal>{' '}
            if the problem persists. We are sorry about the inconvenience.
          </>
        ),
      };
    }

    // Only show card errors directly to the customers. For all other errors we display a generic error instead.
    if (error.data.type === 'card_error' && error.data.message) {
      return {
        title: 'Unable to update payment method',
        message: error.data.message,
      };
    }
  }

  return {
    title: 'Unable to update payment method',
    message: (
      <>
        Please try again, or{' '}
        <Link inline to={SUPPORT_URL.HOME} target="_blank" rel="noreferrer">
          contact support
        </Link>{' '}
        if the problem persists. We are sorry about the inconvenience.
      </>
    ),
  };
};

interface EditPaymentMethodProps {
  setupIntent: CreateSetupIntentResponse;
  subscriptionId: string;
}

const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    fontFamily: 'reMarkableSans, sans-serif',
    fontSizeBase: '16px',
    spacingGridRow: '1rem',
    spacingGridColumn: '1rem',
    fontSizeSm: '14px', // label size
    borderRadius: '4px',
  },
  rules: {
    '.Input': {
      height: '48px',
      padding: '16px',
      border: '1px solid #D2CABC',
      boxShadow: 'none',
      lineHeight: '16px',
    },
    '.Input:focus': {
      outline: '2px solid initial inset',
      boxShadow: 'none',
    },
    '.Input--invalid': {
      boxShadow: '0 0 0 2px var(--colorDanger) inset',
    },
    '.Label': {
      color: 'rgb(34, 34, 34)',
      fontWeight: '600',
    },
    '.Error': {
      color: 'rgb(237, 27, 35)',
    },
  },
};

export const EditPaymentMethod = ({
  setupIntent,
  subscriptionId,
}: EditPaymentMethodProps) => {
  const { paymentElementProps, checkout } = useStripe(
    StripeIntentType.Setup,
    appearance
  );
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const stripeError = getStripeError(checkout);

  const onSubmit = () => {
    checkout.mutate(
      {
        return_url: `${window.location.origin}${location.pathname}?${SearchParam.SubscriptionId}=${subscriptionId}`,
        payment_method_data: {
          billing_details: {
            // Note: If the required fields in the customer's current address is missing, we will tell them to
            //       update their billing information on the Account page before proceeding. Some older accounts
            //       may be missing necessary information here.
            name: setupIntent.data?.name ?? '',
            email: setupIntent.data?.email ?? '',
            address: {
              line1: setupIntent.data?.address?.line1 ?? '',
              line2: setupIntent.data?.address?.line2 ?? '',
              city: setupIntent.data?.address?.city ?? '',
              state: setupIntent.data?.address?.state ?? '',
              country: setupIntent.data.address?.country ?? '',
              postal_code: setupIntent.data?.address?.postal_code ?? '',
            },
            phone: setupIntent.data?.phone ?? '',
          },
        },
      },
      {
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <>
      {stripeError !== null && (
        <NotificationBox
          data-cy="edit-payment-method-stripe-error"
          title={stripeError.title}
          variant="warning"
          className="my-16"
        >
          <Typography variant="body-sm-regular" as="p">
            {stripeError.message}
          </Typography>
        </NotificationBox>
      )}

      <PaymentElement {...paymentElementProps} />

      <Typography variant="body-md-regular" as="p" className="my-16 text-left">
        You hereby authorize reMarkable to charge you automatically each month
        until you cancel your subscription. See full terms and conditions{' '}
        <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
          here
        </Link>
        .
      </Typography>

      <div className="mt-24 flex flex-col flex-wrap justify-center gap-16 xs:flex-row ">
        <Button
          type="submit"
          data-cy="confirm-purchase-button"
          disabled={checkout.isPending}
          loading={checkout.isPending}
          onClick={onSubmit}
          className="w-full xs:w-fit"
          analytics={{
            text: 'Confirm changes',
            location: ComponentLocations.MANAGE_CONNECT.MAIN,
            action: 'confirm edit payment method changes',
          }}
        >
          Confirm changes
        </Button>

        <Modal.Close asChild>
          <Button
            variant="secondary"
            disabled={isLoading}
            className="w-full xs:w-fit"
            analytics={{
              text: 'Cancel',
              location: ComponentLocations.MANAGE_CONNECT.MAIN,
              action: 'cancel edit payment method changes',
            }}
          >
            Cancel
          </Button>
        </Modal.Close>
      </div>
    </>
  );
};
