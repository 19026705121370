import { Typography } from '@remarkable/ark-web';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { useCart } from 'src/api/queries';
import {
  CardContent,
  Feedback,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { stripePromise } from 'src/stripePromise';

import { CheckoutForm } from '../components/CheckoutForm';

export const ConnectOfferCheckoutPage = () => {
  const cart = useCart();

  if (cart.setup.isPending) return <Spinner />;
  if (cart.setup.isError) return <SomethingWentWrong />;

  const options: StripeElementsOptions = {
    clientSecret: cart.setup.data?.clientSecret,
  };

  return (
    <AppPage.Content className="max-w-ll">
      <Sheet>
        <CardContent className="flex flex-col gap-16">
          <Typography variant="heading-md">Activate Connect</Typography>
          <Typography variant="body-lg-regular">
            Verify your payment details to start your free trial.
          </Typography>
        </CardContent>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm cart={cart} />
        </Elements>
      </Sheet>

      <Feedback
        className="my-32"
        title="Did you find what you were looking for?"
        feedbackTargetId="store-connect-checkout"
      />
    </AppPage.Content>
  );
};
