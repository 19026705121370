import * as Sentry from '@sentry/react';
import loglevel, { LogLevelNumbers } from 'loglevel';

const loglevelBySentrySeverityLevel = {
  debug: loglevel.levels.TRACE,
  log: loglevel.levels.DEBUG,
  info: loglevel.levels.INFO,
  warning: loglevel.levels.WARN,
  error: loglevel.levels.ERROR,
  fatal: loglevel.levels.ERROR,
} as const satisfies Record<Sentry.SeverityLevel, LogLevelNumbers>;

export function getLogLevelBySentrySeverityLevel(level?: Sentry.SeverityLevel) {
  if (!level) {
    return;
  }
  return loglevelBySentrySeverityLevel[level] ?? loglevel.levels.DEBUG;
}
