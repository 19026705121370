import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { OneTimeCodeReceived } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';

import { cloudApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const useOneTimeCode = ({
  componentLocation,
}: {
  componentLocation: string;
}) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.oneTimeCode.get,
    queryFn: async () => {
      Sentry?.addBreadcrumb({
        category: 'Pairing',
        message: 'Fetching one time code',
      });
      const response = await cloudApi.getOneTimeCode();

      tracker.trackEvent(
        new OneTimeCodeReceived({
          component_location: componentLocation,
        })
      );

      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
