import { ChangeEventHandler } from 'react';

import { Checkbox, Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import { Check } from 'phosphor-react';
import { useForm } from 'react-hook-form';

import { ButtonClicked, PaymentSucceeded } from 'src/ampli';
import { AnalyticsCoupons } from 'src/analytics';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { CountryCode } from 'src/api/endpoints/ipstackApi.types';
import { useCart } from 'src/api/queries';
import { CartItemConnectWithTrial } from 'src/apps/checkout/app/components/CartItemConnectWithTrial';
import { CheckoutFormState } from 'src/apps/checkout/app/components/Checkout.types';
import { InputZipCode } from 'src/apps/checkout/app/components/InputZipCode';
import { useCheckoutWithoutCard } from 'src/apps/checkout/app/components/useCheckoutWithoutCard';
import {
  Button,
  CountryPicker,
  Divider,
  Form,
  Input,
  Link,
  NotificationBox,
  SplitHorizontalLayout,
  StatePicker,
} from 'src/components';
import { getCountryDataForCountryCodeWithFallback } from 'src/utils/countryDetector';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

export const CheckoutFormWithoutCard = ({
  cart,
}: {
  cart: ReturnType<typeof useCart>;
}) => {
  const form = useForm<CheckoutFormState>({
    defaultValues: {
      country: cart.country.data?.value,
    },
  });

  const { watch, resetField, register, getValues } = form;
  const checkout = useCheckoutWithoutCard();
  const navigate = useNavigate();
  const hasAcceptedTerms = watch('hasAcceptedTerms');

  const selectedCountry = getCountryDataForCountryCodeWithFallback(
    watch('country') as CountryCode
  );
  const isUnitedStates = selectedCountry?.value === 'US';
  const isCanada = selectedCountry?.value === 'CA';

  const onCountryChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const newCountry = getCountryDataForCountryCodeWithFallback(
      e.target.value as CountryCode
    );
    resetField('state');
    resetField('postalCode');
    cart.setCountry(newCountry);
  };

  return (
    <Form
      onSubmit={() => {
        const formValues = getValues();
        checkout.mutate(
          {
            body: {
              data: {
                cart_id: cart.setup.data?.cartId ?? '',
                details: {
                  billing_address: {
                    first_name: formValues.firstName ?? '',
                    last_name: formValues.lastName ?? '',
                    country: cart.country.data?.value ?? '',
                    postcode: formValues.postalCode ?? '',
                    county: formValues.state ?? '',
                  },
                },
              },
            },
          },
          {
            onSuccess: () => {
              tracker.trackEvent(
                new PaymentSucceeded({
                  order_id: '',
                  currency: cart.details.data?.data.currency || '',
                  products: cart.details.data
                    ? [
                        {
                          sku: { value: cart.details.data.data.product.sku },
                          name: { value: cart.details.data.data.product.name },
                          quantity: { value: 1 },
                        },
                      ]
                    : [],
                  revenue: '0',
                  coupon: AnalyticsCoupons.DM_OFFER,
                })
              );
              return navigate({ to: '/store/dm-offer/confirmation' });
            },
          }
        );
      }}
      className="flex flex-col gap-24"
      {...form}
    >
      <Divider className="my-0" />
      <div>
        <CartItemConnectWithTrial />
      </div>
      <Divider className="my-0" />
      <SplitHorizontalLayout>
        <Input
          name="firstName"
          label="First name"
          autoComplete="given-name"
          required
        />
        <Input
          name="lastName"
          label="Last name"
          autoComplete="family-name"
          required
        />
      </SplitHorizontalLayout>
      <SplitHorizontalLayout>
        <CountryPicker onChange={onCountryChange} />
        {isUnitedStates && <InputZipCode cart={cart} />}
      </SplitHorizontalLayout>
      {(isUnitedStates || isCanada) && <StatePicker />}
      <div className="flex items-start gap-8">
        <Checkbox
          data-cy="accept-terms-and-conditions"
          id="terms"
          required
          {...register('hasAcceptedTerms')}
          onClick={() => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                text: `Accept terms and conditions checkbox`,
                action: `toggle accept TOC checkbox ${
                  hasAcceptedTerms ? 'off' : 'on'
                }`,
              })
            );
          }}
        />
        <Typography variant="body-sm-regular" className="my-0">
          <>
            By subscribing you accept the{' '}
            <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
              Terms and Conditions
            </Link>{' '}
            for Connect.
          </>
        </Typography>
      </div>
      {checkout.error && (
        <NotificationBox
          variant="error"
          title={'Something went wrong'}
          className="my-16"
        >
          <Typography variant="body-sm-regular" as="p">
            {checkout.error?.message}
          </Typography>
        </NotificationBox>
      )}
      <Button
        type="submit"
        disabled={checkout.isPending || cart.updateCurrency.isPending}
        loading={checkout.isPending}
        className="ml-auto w-full lm:w-fit"
        variant="primary"
      >
        <span> Activate free trial</span>
        <Check />
      </Button>
    </Form>
  );
};
