import { useAuth0 } from '@auth0/auth0-react';
import {
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  cloudEnterpriseAdminApi,
  cloudEnterpriseEnrollmentApi,
} from '../endpoints';
import { queryKeys } from './queryKeys';

export const queryEnterpriseEnrollmentStatus = () =>
  queryOptions({
    queryKey: queryKeys.enterpriseEnrollment.status,
    queryFn: () => cloudEnterpriseEnrollmentApi.getStatus(),
  });

export function useEnterpriseEnrollmentStatus() {
  return useSuspenseQuery(queryEnterpriseEnrollmentStatus());
}

export function useEnterpriseCreateEnrollment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.init,
    mutationFn: cloudEnterpriseEnrollmentApi.initEnrollment,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseSubmitConnection() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.connection,
    mutationFn: cloudEnterpriseEnrollmentApi.submitConnection,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseEnrollmentVerify() {
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.verify,
    mutationFn: cloudEnterpriseEnrollmentApi.verifyDomain,
  });
}

export function useEnterpriseCancelEnrollment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.cancel,
    mutationFn: cloudEnterpriseEnrollmentApi.cancelEnrollment,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseFinalize() {
  const auth = useAuth0();

  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.finalize,
    mutationFn: async (vars: {
      withMigration: boolean;
      withDomainForwarding: boolean;
      domain: string;
    }) => {
      const finalizeResult =
        await cloudEnterpriseEnrollmentApi.finalizeEnrollment();

      if (vars.withDomainForwarding || vars.withMigration) {
        // Calling /finalize assignes the user a owner role, but we need
        // to manually get a new accesstoken with updated permissions
        // so that we can access the admin api.
        await auth.getAccessTokenSilently({ cacheMode: 'off' });
      }

      if (vars.withMigration) {
        await cloudEnterpriseAdminApi.enableEnterpriseMigration({
          domain: vars.domain,
        });
      }

      if (vars.withDomainForwarding) {
        await cloudEnterpriseAdminApi.enableEnterpriseDomainForwarding({
          domain: vars.domain,
        });
      }

      return finalizeResult;
    },
  });
}
