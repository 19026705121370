// Import the generated route tree
import * as Sentry from '@sentry/react';
import loglevel from 'loglevel';
import ReactDOM from 'react-dom/client';

import { Pageview } from './ampli';
import { tracker } from './analytics/tracker';
import { App } from './app';
import { CONFIG, IS_DEVELOPMENT } from './config';
import { router } from './router';
import './scss/app.scss';
import './scss/tailwind.css';
import { logger } from './utils/logger';
import { categoryLevel } from './utils/logger.categories';
import { getLogLevelBySentrySeverityLevel } from './utils/logger.sentry';

function trackPageView() {
  tracker.trackEvent(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    new Pageview({
      url: window.location.origin + router.history.location.href,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  );
}
// Track landing page (first page view only)
trackPageView();

router.history.subscribe(() => {
  // Track route changes
  trackPageView();
});

try {
  Sentry.init({
    dsn: 'https://56f51ac528f948519fea1f531da7211a@o100763.ingest.sentry.io/1418691',
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({
        dom: {
          serializeAttribute: ['data-cy', 'href', 'id', 'name'],
        },
      }),
    ],
    environment: CONFIG.Environment ?? 'development',
    release: CONFIG.Release,
    // Session Replay
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.03,
    beforeBreadcrumb(breadcrumb) {
      const { category, message, timestamp, level, ...rest } = breadcrumb;

      if (category === 'console') {
        return breadcrumb;
      }

      const finalLevel =
        getLogLevelBySentrySeverityLevel(level) ||
        categoryLevel[category as keyof typeof categoryLevel] ||
        loglevel.levels.TRACE;

      const data = Object.keys(rest).length > 0 ? rest : undefined;

      logger.log({
        category,
        message,
        level: finalLevel,
        timestamp,
        data,
      });

      return breadcrumb;
    },
  });
} catch (e) {
  console.error('Sentry init failed', e);
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function main() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElement = document.getElementById('root')!;
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    // TODO: Enable StrictMode when all components are compatible (checkout confirmation page is not)
    root.render(<App />);
  }
}

async function startDev() {
  const enableMocking = await import('../mocks/mocker').then(
    (m) => m.enableMocking
  );

  return enableMocking().then(() => {
    main();
  });
}

if (IS_DEVELOPMENT) {
  void startDev();
} else {
  main();
}
