import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import { useActivePaperTablets } from 'src/api/queries';

import { SomethingWentWrong } from '../SomethingWentWrong';
import { Spinner } from '../Spinner';
import { ActivateConnectOffer } from './ActivateConnectOffer';
import { ActivateOffer } from './ActivateOffer';
import { DmOffer } from './DmOffer';
import { TimeToSync } from './TimeToSync';

const OFFER_TYPES = {
  RETAIL: 'retailOffer',
  SUBSCRIPTION: 'subscriptionOffer',
  CONNECT: 'connectOffer',
  DM: 'dmOffer',
  STORE: 'store',
};

const getTitleText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.CONNECT:
      return 'Activate your 100-day free Connect trial';
    case OFFER_TYPES.STORE:
    default:
      return 'Never lose track of your notes';
  }
};

const getOfferText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.CONNECT:
      return 'Enjoy seamless note-taking with unlimited cloud storage and sync, and up to three years added device protection.';
    case OFFER_TYPES.STORE:
      return 'With Connect, your notes are safely stored, synced, and ready to use on your paper tablet and in the apps. ';
    default:
      return 'Never lose track of your notes';
  }
};

const getTimeToSyncTitle = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    default:
      return 'Never lose track of your notes';
  }
};

const getTimeToSyncText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.CONNECT:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a free 100-day Connect trial.';
    case OFFER_TYPES.STORE:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a Connect subscription.';
    default:
      return 'Never lose track of your notes';
  }
};

const getButtonText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.STORE:
      return 'Subscribe to Connect';
    case OFFER_TYPES.CONNECT:
      return 'Activate free trial';
    case OFFER_TYPES.DM:
      return 'Sync files';
    default:
      return 'Activate Connect';
  }
};

export const HeroOffer = ({
  checkoutType,
  componentLocation,
  expirationDate,
}: {
  checkoutType: CheckoutType;
  componentLocation: string;
  expirationDate: string | null;
}) => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) {
    return <Spinner />;
  }

  if (activePaperTablets.isError) {
    return <SomethingWentWrong />;
  }

  if (checkoutType === OFFER_TYPES.DM) {
    return (
      <DmOffer
        analytics={{
          location: `${componentLocation}-${checkoutType}`,
          text: getButtonText(checkoutType),
          action: 'Activate connect for sync',
        }}
      />
    );
  }

  // Covers both store and connect offer scenarios.
  if (activePaperTablets.data.length > 1) {
    return (
      <TimeToSync
        title={getTimeToSyncTitle(checkoutType)}
        type={checkoutType}
        text={getTimeToSyncText(checkoutType)}
        buttonText={getButtonText(checkoutType)}
        analytics={{
          location: `${componentLocation}-${checkoutType}`,
          text: getButtonText(checkoutType),
          action: 'Activate connect for sync',
        }}
        expirationDate={expirationDate}
      />
    );
  }

  return (
    <ActivateOffer
      type={checkoutType}
      title={getTitleText(checkoutType)}
      text={getOfferText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect',
      }}
      expirationDate={expirationDate}
    />
  );
};

// This also covers non offer scenario
export const Offer = ({
  checkoutType,
  componentLocation,
  expirationDate,
}: {
  checkoutType: CheckoutType;
  componentLocation: string;
  expirationDate: string | null;
}) => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) {
    return <Spinner />;
  }

  if (activePaperTablets.isError) {
    return <SomethingWentWrong />;
  }

  // Device migration offer
  if (checkoutType === OFFER_TYPES.DM) {
    return (
      <DmOffer
        analytics={{
          location: `${componentLocation}-${checkoutType}`,
          text: getButtonText(checkoutType),
          action: 'Activate connect for sync',
        }}
      />
    );
  }
  // Connect offer
  if (checkoutType === OFFER_TYPES.CONNECT) {
    if (activePaperTablets.data.length > 1) {
      return (
        <div className=" mt-32  lm:mt-56 ll:mb-48 ll:mt-64">
          <TimeToSync
            title={getTimeToSyncTitle(checkoutType)}
            type={checkoutType}
            text={getTimeToSyncText(checkoutType)}
            buttonText={getButtonText(checkoutType)}
            analytics={{
              location: `${componentLocation}-${checkoutType}`,
              text: getButtonText(checkoutType),
              action: 'Activate connect for sync',
            }}
            expirationDate={expirationDate}
          />
        </div>
      );
    }

    return (
      <ActivateConnectOffer
        type={checkoutType}
        title={getTitleText(checkoutType)}
        text={getOfferText(checkoutType)}
        buttonText={getButtonText(checkoutType)}
        buttonFixed
        analytics={{
          location: `${componentLocation}-${checkoutType}`,
          text: 'Activate free trial',
          action: 'Activate connect',
        }}
      />
    );
  }

  // Here there is no offer. Normal connect selling points
  return (
    <ActivateOffer
      type={checkoutType}
      title={getTitleText(checkoutType)}
      text={getOfferText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      buttonFixed
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect',
      }}
      expirationDate={expirationDate}
    />
  );
};
