import { Typography } from '@remarkable/ark-web';

import { useCart } from 'src/api/queries';
import { createContentSwitch } from 'src/utils/createContentSwitch';
import { formatDate } from 'src/utils/formatDate';
import { formatAmount } from 'src/utils/productUtils';

import { Price } from './Price';

export const CartItemConnectWithTrial = () => {
  const cart = useCart();
  const showOnCheckoutType = createContentSwitch(cart.checkoutType);

  return (
    <>
      <div data-cy="connect-plan-row" className="flex justify-between">
        <p className="my-4 font-semibold">
          {showOnCheckoutType({
            dmOffer: '30-day free trial',
            connectOffer: '100-day free trial',
          })}
        </p>
        <p className="my-4">
          <b data-cy="connect-plan-row-price">
            {formatAmount(0, cart.details.data?.data.currency)}
          </b>
        </p>
      </div>
      <div className="flex justify-between" data-cy="billing-starts-row">
        <p data-cy="billing-start-date" className="my-4">
          {showOnCheckoutType({
            ['connectOffer']: (
              <>
                Billing starts&nbsp;
                {formatDate(cart.details.data?.data.billingDate ?? '')}
              </>
            ),
            ['dmOffer']:
              'We’ll send you a reminder email before your trial ends.',
          })}
        </p>
        <p className="my-4">
          <Price cart={cart} showTaxStatus />
        </p>
      </div>
      <Typography variant="body-sm-regular" className="mt-16">
        {showOnCheckoutType({
          ['connectOffer']:
            'We’ll send you a reminder email before your free trial ends.',
        })}
      </Typography>
    </>
  );
};
