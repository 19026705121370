import { Typography } from '@remarkable/ark-web';
import { ConfirmPaymentData } from '@stripe/stripe-js';
import { UseMutationResult } from '@tanstack/react-query';

import { CheckoutsCheckoutResponse } from 'src/api/endpoints/storeApi.types';
import { CustomStripeError } from 'src/utils/stripe';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { CheckoutError, CheckoutErrorType } from './Checkout.types';

export const getFormError = (
  retaiOfferCheckout: UseMutationResult<
    CheckoutsCheckoutResponse['data'],
    unknown,
    Record<string, unknown>,
    unknown
  >,
  stripeCheckout: UseMutationResult<unknown, Error, ConfirmPaymentData, unknown>
): null | CheckoutError => {
  if (retaiOfferCheckout.error === null && stripeCheckout.error === null) {
    return null;
  }

  if (retaiOfferCheckout.error) {
    return {
      title: 'Unable to complete checkout',
      message: (
        <Typography variant="body-sm-regular">
          Please try again, or{' '}
          <a href={SUPPORT_URL.HOME} target="_blank" rel="noreferrer">
            contact support
          </a>{' '}
          if the problem persists. We are sorry about the inconvenience.
        </Typography>
      ),
      type: CheckoutErrorType.FETCH_CHECKOUT_ERROR,
    };
  }

  if (stripeCheckout.error) {
    if (stripeCheckout.error instanceof CustomStripeError) {
      if (stripeCheckout.error.data.type === 'validation_error') {
        // This message will not produce an error message box, but we will use it to cross the user to the correct part of the screen
        return {
          title: '',
          message: '',
          type: CheckoutErrorType.STRIPE_VALIDATION_ERROR,
        };
      }

      if (stripeCheckout.error.data.message) {
        return {
          title: 'Unable to complete checkout',
          message: stripeCheckout.error.data.message,
          type: CheckoutErrorType.STRIPE_ERROR,
        };
      }
    }

    return {
      title: 'Unable to complete checkout',
      message: (
        <Typography variant="body-sm-regular">
          Please try again, or{' '}
          <a href={SUPPORT_URL.HOME} target="_blank" rel="noreferrer">
            contact support
          </a>{' '}
          if the problem persists. We are sorry about the inconvenience.
        </Typography>
      ),
      type: CheckoutErrorType.STRIPE_ERROR,
    };
  }

  return null;
};
