import * as Sentry from '@sentry/react';
import {
  MutationCache,
  QueryCache,
  QueryClientConfig,
} from '@tanstack/react-query';

import { tracker } from 'src/analytics/tracker';
import { getSubscriptionForTracking } from 'src/analytics/utils';
import {
  filterActiveBrowserExtensionClients,
  filterActiveDesktopAppClients,
  filterActiveMobileAppClients,
  filterActivePaperTabletClients,
} from 'src/apps/device/utils/utils';
import { getCurrentMember } from 'src/utils/getCurrentMember';

import { HTTPError } from './createApiClient.types';
import { ClientType } from './endpoints/cloudApi.types';
import { getMergedDevices } from './endpoints/cloudApi.utils';
import {
  Subscription,
  SubscriptionMembersResponse,
} from './endpoints/storeApi.types';
import { queryKeys } from './queries';
import { RetryException } from './queries/types';

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error instanceof RetryException) {
          if (error.attempts === undefined) return true;

          const canRetry = error.attempts - failureCount > 0;

          if (!canRetry) {
            Sentry?.addBreadcrumb({
              category: 'Query',
              message: 'Max manual retries reached.',
              data: {
                attempts: failureCount,
              },
            });
          }
          return canRetry;
        }

        if (
          error instanceof HTTPError &&
          error.response.status >= 500 &&
          failureCount < 3
        ) {
          Sentry?.addBreadcrumb({
            category: 'Query',
            message: 'Request failed, retry triggered.',
            data: {
              attempts: failureCount,
            },
          });
          return true;
        }

        Sentry?.addBreadcrumb({
          category: 'Query',
          message: 'Max retries reached.',
          data: {
            attempts: failureCount,
          },
        });

        return false;
      },
    },
  },
  // stubbing in the component tests does not like the queryCache
  queryCache: new QueryCache({
    onSuccess(data, query) {
      // Breadcrumbs are used to track the steps that lead to an error.
      // They will be added to an error report if, and only if, an error occurs.
      Sentry?.addBreadcrumb({
        category: 'Query',
        data: { query_key: query.queryKey },
        message: `[${query.queryKey.join(', ')}] | Success`,
      });

      if (query.queryKey === queryKeys.subscriptions.all) {
        const subForTracking = getSubscriptionForTracking(data as Subscription);
        Sentry.setTags({
          'rm.subscription_type': subForTracking.subscription_type,
          'rm.subscription_status': subForTracking.subscription_status,
          'rm.subscription_payment_interval':
            subForTracking.subscription_payment_interval,
        });

        tracker.identify({
          myrm_user_parameters: JSON.stringify({
            subscription_type: subForTracking.subscription_type,
            subscription_status: subForTracking.subscription_status,
            subscription_payment_interval:
              subForTracking.subscription_payment_interval,
          }),
        });
      }

      if (query.queryKey === queryKeys.devices.all) {
        const data = query.state.data as Awaited<
          ReturnType<typeof getMergedDevices>
        >;

        const active_tablets = filterActivePaperTabletClients(data);

        const active_tablets_count = active_tablets.length;
        const active_rm1_count = active_tablets.filter((d) =>
          d.clientType.startsWith(ClientType.RM10)
        ).length;
        const active_rm2_count = active_tablets.filter((d) =>
          d.clientType.startsWith(ClientType.RM11)
        ).length;
        const active_rm_paper_pro_count = active_tablets.filter((d) =>
          d.clientType.startsWith(ClientType.RM02)
        ).length;
        const active_unknown_tablet_count = active_tablets.filter(
          (d) =>
            !d.clientType.startsWith(ClientType.RM10) &&
            !d.clientType.startsWith(ClientType.RM11) &&
            !d.clientType.startsWith(ClientType.RM02)
        ).length;
        const active_mobile_apps_count =
          filterActiveMobileAppClients(data).length ?? 0;
        const active_desktop_apps_count =
          filterActiveDesktopAppClients(data).length ?? 0;
        const active_browser_extensions_count =
          filterActiveBrowserExtensionClients(data).length ?? 0;

        Sentry.setTags({
          'rm.active_rm1_count': active_rm1_count,
          'rm.active_rm2_count': active_rm2_count,
          'rm.active_rm_paper_pro_count': active_rm_paper_pro_count,
          'rm.active_unknown_tablets_count': active_unknown_tablet_count,
          'rm.active_tablets_count': active_tablets_count,
          'rm.active_mobile_apps_count': active_mobile_apps_count,
          'rm.active_desktop_apps_count': active_desktop_apps_count,
          'rm.active_browser_extensions_count': active_browser_extensions_count,
        });

        tracker.identify({
          myrm_user_parameters: JSON.stringify({
            active_rm1_count,
            active_rm2_count,
            active_rm_paper_pro_count,
            active_unknown_tablet_count,
            active_tablets_count,
            active_mobile_apps_count,
            active_desktop_apps_count,
            active_browser_extensions_count,
          }),
        });
      }

      if (query.queryKey.includes('members')) {
        const subscription_role =
          getCurrentMember(data as SubscriptionMembersResponse)?.role ?? 'none';

        Sentry.setTags({ 'rm.subscription_role': subscription_role });

        tracker.identify({
          myrm_user_parameters: JSON.stringify({
            subscription_role: subscription_role,
          }),
        });
      }
    },
    onError(error, query) {
      if (error instanceof RetryException) {
        return;
      }

      Sentry?.addBreadcrumb({
        category: 'Query',
        message: 'Query Error',
        data: { query_key: query.queryKey, error: error.message },
      });
    },
  }),
  mutationCache: new MutationCache({
    onMutate(variables, mutation) {
      Sentry?.addBreadcrumb({
        category: 'Mutation',
        data: {
          variables,
          mutation_key: mutation.options.mutationKey,
          meta: mutation.meta,
        },
        message: 'Mutation Start',
      });
    },
    onSuccess(_, variables, context, mutation) {
      Sentry?.addBreadcrumb({
        category: 'Mutation',
        data: {
          variables,
          mutation_key: mutation.options.mutationKey,
          meta: mutation.meta,
          context,
        },
        message: 'Mutation Success',
      });
    },
    onError(error, variables, context, mutation) {
      if (error instanceof RetryException) {
        return;
      }

      Sentry?.addBreadcrumb({
        category: 'Mutation',
        message: 'Mutation Error',
        data: {
          variables,
          mutation_key: mutation.options.mutationKey,
          meta: mutation.meta,
          context,
          error: error.message,
        },
      });
    },
  }),
};
