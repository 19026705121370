export type ExperimentKeys = 'endpoint-checkout-v4-cart'; // E.g. 'connect-offer-rollback-jan-2025'
export type ExperimentIds = 'i2vMgfr3SUTJtEBP8Xz7uF'; // E.g. 'oHpxPWHos5wBsfcQZxoQhZ'

export enum ExperimentVariant {
  CG = 'CG',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export interface Variant {
  variantName: ExperimentVariant;
  weight: number;
}

export type Experiment = {
  name: string;
  id: ExperimentIds;
  variants: Variant[];
};

export type ExperimentRecord = Record<ExperimentKeys, Experiment>;

// ========================================================================
// Configure your experiment here
// ========================================================================
export const Experiments: Partial<ExperimentRecord> = {
  'endpoint-checkout-v4-cart': {
    id: 'i2vMgfr3SUTJtEBP8Xz7uF',
    name: 'Endpoint Checkout V4 Cart',
    variants: [
      { variantName: ExperimentVariant.CG, weight: 0.8 },
      { variantName: ExperimentVariant.A, weight: 0.2 },
    ],
  },
};
