import {
  ChangeEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Check } from '@phosphor-icons/react';
import { Checkbox, Typography } from '@remarkable/ark-web';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useIsMutating } from '@tanstack/react-query';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useForm } from 'react-hook-form';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { queryKeys, useCart } from 'src/api/queries';
import {
  Button,
  Divider,
  Form,
  Input,
  Link,
  SomethingWentWrong,
  Spinner,
  SplitHorizontalLayout,
} from 'src/components';
import { InputTaxId } from 'src/components/InputTaxId';
import { COUNTRIES, getSupportedCountries } from 'src/utils/countryList';
import { createContentSwitch } from 'src/utils/createContentSwitch';
import { StripeIntentType, useStripe } from 'src/utils/stripe';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { CountryPicker } from '../../../../components/CountryPicker';
import { StatePicker } from '../../../../components/StatePicker';
import { CartItemConnect } from './CartItemConnect';
import { CartItemConnectWithTrial } from './CartItemConnectWithTrial';
import { CheckoutErrorType, CheckoutFormState } from './Checkout.types';
import { ErrorBox } from './ErrorBox';
import { InputZipCode } from './InputZipCode';
import { Price } from './Price';
import { useCheckout } from './useCheckout';

const TermsAndConditionsLink = () => (
  <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
    Terms and Conditions
  </Link>
);

export const CheckoutForm = ({
  cart,
}: {
  cart: ReturnType<typeof useCart>;
}) => {
  const form = useForm<CheckoutFormState>({
      defaultValues: {
        country: cart.country.data?.value,
      },
    }),
    { watch, resetField } = form;

  const errorMessageRef = useRef<HTMLDivElement | null>(null);
  const paymentElementRef = useRef<HTMLDivElement | null>(null);
  const hasAcceptedTerms = watch('hasAcceptedTerms');
  const supportedCountries = getSupportedCountries();

  const selectedCountry = cart.country.data;

  const stripeIntentType =
    cart.checkoutType === 'store'
      ? StripeIntentType.Payment
      : StripeIntentType.Setup;
  const { stripe, paymentElementProps } = useStripe(stripeIntentType);

  const checkout = useCheckout();

  const errorMessage = checkout.error?.message || null;
  const errorMessageType = checkout.error?.type || null;

  useLayoutEffect(() => {
    if (
      errorMessage ||
      errorMessageType === CheckoutErrorType.STRIPE_VALIDATION_ERROR
    ) {
      switch (errorMessageType) {
        case CheckoutErrorType.STRIPE_VALIDATION_ERROR:
          paymentElementRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
          break;
        case CheckoutErrorType.STRIPE_ERROR:
        default:
          errorMessageRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
          break;
      }
    }
  }, [errorMessage, errorMessageType, errorMessageRef, paymentElementRef]);

  // Country can change outside form
  useEffect(() => {
    if (cart.country.data && cart.country.data !== selectedCountry) {
      form.resetField('country', { defaultValue: cart.country.data.value });
      resetField('state');
      resetField('postalCode');
    }
  }, [cart.country.data]);

  const onCountryChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const newCountry = COUNTRIES.find((c) => c.value === e.target.value);

    if (!newCountry) {
      return;
    }

    resetField('state');
    resetField('postalCode');
    cart.setCountry(newCountry);
  };

  // ======================= Form ========================

  const isUnitedStates = selectedCountry?.value === 'US';
  const isCanada = selectedCountry?.value === 'CA';

  // ================== Visuals ==================

  const [showBillingInfo, setShowBillingInfo] = useState(false);

  const isMutatingCurrency =
    useIsMutating({
      mutationKey: queryKeys.checkout.currency,
    }) > 0;
  const isMutatingTax =
    useIsMutating({ mutationKey: queryKeys.checkout.tax }) > 0;
  const isUpdatingCurrency = cart.updateCurrency.isPending;

  if (
    cart.setup.isPending ||
    cart.country.isPending ||
    cart.details.isPending
  ) {
    return <Spinner />;
  }

  if (cart.details.isError || cart.setup.isError || cart.country.isError) {
    return <SomethingWentWrong />;
  }

  // We should disable the most important fields in the form while performing these actions.
  const disableCurrencyRelatedFormFields =
    isMutatingCurrency || isMutatingTax || isUpdatingCurrency;

  const showOnCheckoutType = createContentSwitch(cart.checkoutType);

  return (
    <div className="text-black" data-cy="checkout-form">
      <Form
        onSubmit={(data) => {
          checkout.submit(data);
        }}
        className="flex flex-col gap-24"
        {...form}
      >
        <Divider className="my-0" />

        <div>
          {showOnCheckoutType({
            ['connectOffer']: <CartItemConnectWithTrial />,
            ['store']: <CartItemConnect />,
          })}
        </div>

        <Divider className="my-0" />

        <SplitHorizontalLayout>
          <Input
            name="firstName"
            label="First name"
            autoComplete="given-name"
            required
          />
          <Input
            name="lastName"
            label="Last name"
            autoComplete="family-name"
            required
          />
        </SplitHorizontalLayout>

        <SplitHorizontalLayout>
          <CountryPicker
            onChange={onCountryChange}
            countries={supportedCountries}
            disabled={disableCurrencyRelatedFormFields}
            value={cart.country.data?.value}
          />
          {isUnitedStates && (
            <InputZipCode
              cart={cart}
              disabled={disableCurrencyRelatedFormFields}
            />
          )}
        </SplitHorizontalLayout>

        {(isUnitedStates || isCanada) && (
          <StatePicker
            country={cart.country.data?.value}
            disabled={disableCurrencyRelatedFormFields}
          />
        )}

        <div ref={paymentElementRef}>
          <PaymentElement {...paymentElementProps} />
        </div>

        <Divider className="-mt-16 mb-0" />

        <button
          data-cy="billing-info-optional"
          className="body-lg-regular relative h-32 w-full cursor-pointer border-none bg-none text-left"
          onClick={(e) => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                text: e.currentTarget.innerText,
                action: 'toggle billing info in checkout',
              })
            );
            setShowBillingInfo(!showBillingInfo);
          }}
          type="button"
        >
          <Typography variant="interface-lg-semibold">
            Billing information <span className="font-regular">(optional)</span>
          </Typography>
          {!showBillingInfo ? (
            <CaretDown size={32} className="absolute right-12 top-0" />
          ) : (
            <CaretUp size={32} className="absolute right-12 top-0" />
          )}
        </button>

        {showBillingInfo && (
          <>
            <Input
              data-cy="billing-address1"
              label="Billing address 1"
              autoComplete="street-address"
              name="billingAddress1"
            />
            <Input
              data-cy="billing-address2"
              name="billingAddress2"
              label="Billing address 2"
            />

            <SplitHorizontalLayout>
              {!isUnitedStates && (
                <Input
                  data-cy="billing-post-code"
                  name="postalCode"
                  label="Postal code"
                  inputMode="numeric"
                  autoComplete="postal-code"
                />
              )}
              <Input
                data-cy="billing-city"
                name="city"
                label="City"
                autoComplete="address-level2"
              />
            </SplitHorizontalLayout>

            <Input
              data-cy="billing-company-name"
              name="companyName"
              label="Company name"
              autoComplete="organization"
            />
            <InputTaxId className="my-0" country={selectedCountry} />
          </>
        )}

        <Divider className="my-0" />

        <div className="flex items-start gap-8">
          <Checkbox
            data-cy="accept-terms-and-conditions"
            id="terms"
            name="hasAcceptedTerms"
            required
            onClick={() => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                  text: `Accept terms and conditions checkbox`,
                  action: `toggle accept TOC checkbox ${
                    hasAcceptedTerms ? 'off' : 'on'
                  }`,
                })
              );
            }}
          ></Checkbox>
          <Typography variant="body-sm-regular" className="my-0">
            {showOnCheckoutType({
              ['store']: (
                <>
                  By subscribing you accept the <TermsAndConditionsLink /> for
                  Connect and agree to a payment of <Price cart={cart} />
                  {'. '}
                  Cancel anytime.
                </>
              ),
              ['connectOffer']: (
                <>
                  I accept the <TermsAndConditionsLink /> for Connect and agree
                  to a payment of <Price cart={cart} /> once the free
                  subscription period ends. Cancel anytime.
                </>
              ),
            })}
          </Typography>
        </div>

        <ErrorBox error={checkout.error || undefined} ref={errorMessageRef} />

        {stripe && (
          <div className="mt-24 flex justify-center">
            <Button
              data-cy="activate-connect-button"
              type="submit"
              variant="primary"
              className="ml-auto w-full lm:w-fit"
              disabled={disableCurrencyRelatedFormFields}
              loading={checkout.isPending}
              onClick={(e) => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location:
                      ComponentLocations.CHECKOUT.CONNECT_CHECKOUT,
                    text: e.currentTarget.innerText,
                    action: 'submit payment information',
                  })
                );
              }}
            >
              <span>
                {showOnCheckoutType({
                  ['connectOffer']: 'Activate free trial',
                  ['store']: 'Subscribe',
                })}{' '}
              </span>
              <Check />
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
