import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';

const samlErrorSearchSchema = z.object({
  audience: z.string().optional(),
  client_id: z.string().optional(),
  code_challenge_method: z.string().optional(),
  code_challenge: z.string().optional(),
  auth0Client: z.string().optional(),
  connection: z.string(),
  organization: z.string().optional(),
  login_hint: z.string().optional(),
  /** Custom param set by our Auth0 higher order component, only intended to be used in the saml-error flow. */
  redirect_path: z.string().optional(),
});

export const Route = createFileRoute('/_public/saml-error')({
  validateSearch: zodSearchValidator(samlErrorSearchSchema),
  async beforeLoad({ context, search }) {
    const auth = await context.auth;

    const returnUrl = new URL(
      search.redirect_path ?? '/',
      window.location.origin
    );

    await auth.loginWithRedirect({
      authorizationParams: {
        connection: search.connection,
        organization: search.organization ?? search.connection,
        audience: search.audience,
        login_hint: search.login_hint,
      },
      appState: {
        returnTo: sanitizeRedirectUrl(
          returnUrl.pathname + returnUrl.search + returnUrl.hash
        ),
      },
    });
  },
});
