import { forwardRef, Ref } from 'react';

import { Typography } from '@remarkable/ark-web';

import { NotificationBox } from 'src/components';

import { CheckoutError, CheckoutErrorType } from './Checkout.types';

interface ErrorBoxProps {
  error?: CheckoutError;
}
const ErrorBoxComponent = (
  { error }: ErrorBoxProps,
  ref: Ref<HTMLDivElement>
) => {
  if (!error || !error.message) return null;

  if (error.type === CheckoutErrorType.STRIPE_VALIDATION_ERROR) return null;

  return (
    <div ref={ref}>
      <NotificationBox variant="error" title={error.title} className="my-16">
        {typeof error.message === 'string' ? (
          <Typography variant="body-sm-regular" as="p">
            {error.message}
          </Typography>
        ) : (
          error.message
        )}
      </NotificationBox>
    </div>
  );
};

export const ErrorBox = forwardRef<HTMLDivElement, ErrorBoxProps>(
  ErrorBoxComponent
);
