import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';

import { Button } from './Button';

interface Props {
  eventId?: string;
}
export const SentryFeedbackReportButton = ({ eventId: propEventId }: Props) => {
  const eventId = propEventId ?? Sentry.lastEventId();
  const auth = useAuth0();
  return (
    <Button
      data-cy="sentry-feedback-report-button"
      variant="secondary"
      onClick={() => {
        Sentry.showReportDialog({
          title: 'Looks like something went wrong.',
          labelEmail: 'Email address',
          labelSubmit: 'Submit crash report',
          user: {
            email: auth.user?.email,
            name: auth.user?.name,
          },
          eventId: eventId || Sentry.captureMessage('Fallback sentry event'), // We can't always rely on lastEventId to be present. In that case, we create a new event.
        });
        tracker.trackEvent(
          new ButtonClicked({
            component_location:
              ComponentLocations.ERROR_MODULES.SOMETHING_WENT_WRONG,
            text: 'Report feedback',
            action: 'Report feedback',
          })
        );
      }}
    >
      Report feedback
    </Button>
  );
};
