import { ArrowsClockwise } from '@phosphor-icons/react';
import { Cloud, NotePencil, ShieldCheck, CheckCircle } from 'phosphor-react';

export const valueProps = {
  enterprise: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Cloud,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
  },

  connectWithSeats: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Cloud,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
  },
  connect: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Cloud,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
  },
  connectTestA: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: CheckCircle,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: CheckCircle,
    },
    mobileAndDesktopNoteTaking: {
      title: 'Mobile and desktop note-taking',
      id: 'mobileAndDesktopNoteTaking',
      icon: CheckCircle,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: CheckCircle,
    },
  },
};
