import { HTMLProps } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { queryKeys, useCart } from 'src/api/queries';
import { Spinner } from 'src/components';
import { formatAmount } from 'src/utils/productUtils';

interface PriceProps extends HTMLProps<HTMLDivElement> {
  cart: ReturnType<typeof useCart>;
  showTaxStatus?: boolean;
}
export const Price = ({ cart, showTaxStatus, ...props }: PriceProps) => {
  const isMutatingCurrency = useIsMutating({
    mutationKey: queryKeys.checkout.currency,
  });
  const isMutatingTax = useIsMutating({ mutationKey: queryKeys.checkout.tax });

  const formattedPrice = formatAmount(
    cart.details.data?.data.productPrice.total ?? 0,
    cart.details.data?.data.currency
  );

  const taxStatus = cart.details.data?.data.tax.isIncluded
    ? '(incl. VAT)'
    : cart.details.data?.data.tax.isCalculated
    ? '(incl. tax)'
    : '(excl. tax)';

  if (cart.details.isError) {
    return <span {...props}>(could not calculate tax)</span>;
  }

  if (isMutatingCurrency || isMutatingTax || cart.updateCurrency.isPending) {
    return (
      <span {...props}>
        <Spinner inline />
      </span>
    );
  }

  return (
    <span {...props}>
      {formattedPrice}/mo.{showTaxStatus && ' ' + taxStatus}
    </span>
  );
};
