import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Feedback } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Offer } from 'src/components/Offers/Offer';

export const ConnectLandingPage = () => {
  return (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Content className="max-w-l2xl pt-64">
        <Offer
          checkoutType="store"
          componentLocation={ComponentLocations.CHECKOUT.LANDING}
          expirationDate={null}
        />

        <Feedback
          className="my-32"
          title="Did you find what you were looking for?"
          feedbackTargetId="store-connect-landing"
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
