import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { storeApiClient } from '../endpoints/storeApi';
import { FeedbackTargetValues } from '../endpoints/storeApi.types';
import { queryKeys } from './queryKeys';

export const categories = {
  annual: ['annual', 'yearly'],
  bug: ["didn't work", 'bug', 'error', 'crash'],
  invoice: ['invoice', 'receipt'],
  connect: ['connect', 'subscription', 'activation', 'free trial', 'subscribe'],
  device: ['one time code'],
};

export function categorizeComment(comment: string): string {
  if (!comment) return 'unknown';

  const lowerComment = comment.toLowerCase();

  for (const [category, keywords] of Object.entries(categories)) {
    for (const keyword of keywords) {
      // use regex to allow singular/plural forms
      const regex = new RegExp(`\\b${keyword}s?\\b`, 'i');
      if (regex.test(lowerComment)) {
        return category;
      }
    }
  }
  return 'unknown';
}

export function useFeedbackValue(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.value(targetId),
    mutationFn: async (args: { feedbackValue: boolean }) => {
      return storeApiClient
        .POST('/v1/myrm/feedback/{feedbackTarget}', {
          params: { path: { feedbackTarget: targetId } },
          body: { feedbackValue: args.feedbackValue },
        })
        .then((res) => res.data?.data ?? null);
    },
  });
}

export function useAddFeedbackComment(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.addComment(targetId),
    mutationFn: async (args: { comment: string; instanceId: string }) => {
      // We create an event so that we can get trace for the feedback
      const eventId = Sentry.captureEvent({
        level: 'debug',
        message: 'Added feedback comment',
        tags: {
          feedbackTarget: targetId,
        },
      });

      void Sentry?.sendFeedback({
        associatedEventId: eventId,
        message: args.comment,
        tags: {
          feedbackTarget: targetId,
          feedbackId: args.instanceId,
          feedbackCategory: categorizeComment(args.comment),
        },
        url: window.location.href,
      });

      return storeApiClient
        .PUT('/v1/myrm/feedback/{feedbackTarget}/{feedbackUUID}/comment', {
          params: {
            path: {
              feedbackTarget: targetId,
              feedbackUUID: args.instanceId,
            },
          },
          body: { comment: args.comment },
        })
        .then((res) => res.data ?? null);
    },
  });
}

export function useFeedbackComment(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.comment(targetId),
    mutationFn: async (args: { comment: string }) => {
      // We create an event so that we can get trace for the feedback
      const eventId = Sentry.captureEvent({
        level: 'debug',
        message: 'Added feedback comment',
      });

      void Sentry?.sendFeedback({
        associatedEventId: eventId,
        message: args.comment,
        tags: {
          feedbackTarget: targetId,
          feedbackCategory: categorizeComment(args.comment),
        },
        url: window.location.href,
      });

      return storeApiClient
        .POST('/v1/myrm/feedback/{feedbackTarget}/comment', {
          params: { path: { feedbackTarget: targetId } },
          body: { comment: args.comment },
        })
        .then((res) => res.data ?? null);
    },
  });
}
