import '@auth0/auth0-react';
import { createFileRoute, redirect } from '@tanstack/react-router';

import {
  createQueryCheckoutAllowed,
  createQueryDevices,
  createQuerySubscriptions,
} from 'src/api/queries';
import { HomeApp } from 'src/apps/home/HomeApp';

export const Route = createFileRoute('/_auth/_layout/')({
  component: Index,
  beforeLoad({ location }) {
    if (location.hash === 'mobile') {
      throw redirect({
        to: '/device/mobile/connect',
      });
    }

    if (location.hash === 'desktop') {
      throw redirect({
        to: '/device/desktop/connect',
      });
    }

    if (location.hash === 'browser') {
      throw redirect({
        to: '/device/browser/connect',
      });
    }
  },
  async loader({ context }) {
    return await Promise.all([
      context.queryClient.ensureQueryData(createQuerySubscriptions()),
      context.queryClient.ensureQueryData(createQueryDevices()),
      context.queryClient.ensureQueryData(createQueryCheckoutAllowed()),
    ]);
  },
});

function Index() {
  return <HomeApp />;
}
