import { components } from 'apis/cloud-enterprise-enrollment-api';

export type EnterpriseEnrollmentStatus = components['schemas']['Status'];

export const EnterpriseEnrollmentStep = {
  NotStarted: 0,
  CreateOrganization: 1,
  VerifyDomain: 2,
  SetupSAML: 3,
  VerifySAML: 4,
  Confirm: 5,
  Finalized: 6,
} as const;

export type EnterpriseEnrollmentStep =
  (typeof EnterpriseEnrollmentStep)[keyof typeof EnterpriseEnrollmentStep];
