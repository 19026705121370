import { createFileRoute, redirect } from '@tanstack/react-router';

import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/_with-org')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw redirect({ to: '/login' });
    }

    if (userHelpers.isLoggedInToOrganization(auth.user)) {
      return;
    }

    if (userHelpers.getOrganizationId(auth.user)) {
      throw redirect({
        to: '/login',
        search: {
          login_hint: userHelpers.getEmail(auth.user),
          organization: userHelpers.getOrganizationId(auth.user),
          redirect: location.href,
        },
      });
    }

    throw redirect({
      statusCode: 403,
      to: '/',
      search: {
        access_denied: 'Only users with an organization can access this page.',
      },
    });
  },
});
