/* eslint-disable @typescript-eslint/unbound-method */
import loglevel, { LogLevelNumbers } from 'loglevel';

export const LogCategory = {
  Auth: 'Auth',
  Query: 'Query',
  Mutation: 'Mutation',
  Checkout: 'Checkout',
  Stripe: 'Stripe',
  Pairing: 'Pairing',
  UIClick: 'ui.click',
} as const;

export type LogCategory = (typeof LogCategory)[keyof typeof LogCategory];

export const categoryLevel = {
  Auth: loglevel.levels.INFO,
  Checkout: loglevel.levels.DEBUG,
  Mutation: loglevel.levels.TRACE,
  Query: loglevel.levels.TRACE,
  Pairing: loglevel.levels.DEBUG,
  Stripe: loglevel.levels.DEBUG,
  'ui.click': loglevel.levels.DEBUG,
} as const satisfies Record<LogCategory, LogLevelNumbers>;
