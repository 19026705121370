import { ReactNode } from 'react';

interface SplitHorizontalLayoutProps {
  children: ReactNode;
  className?: string;
}

export const SplitHorizontalLayout = ({
  children,
  className = '',
}: SplitHorizontalLayoutProps) => {
  return (
    <div
      className={`flex w-full flex-col items-stretch gap-16 ls:flex-row ls:gap-24 ${className}`}
    >
      {children}
    </div>
  );
};
