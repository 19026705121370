import { useAuth0 } from '@auth0/auth0-react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { userHelpers } from 'src/utils/userHelpers';

import { cloudEnterpriseAdminApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const queryEnterpriseRoles = () =>
  queryOptions({
    queryKey: queryKeys.enterpriseAdmin.roles,
    queryFn: () => cloudEnterpriseAdminApi.getRoles(),
  });

export const useAddRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars: { memberId: string; roleId: string }) =>
      toast.promise(cloudEnterpriseAdminApi.addRoleOnUser(vars), {
        loading: 'Adding role...',
        error: 'Unable to add role',
        success: 'Role added',
      }),
    onSettled() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.fleet.members,
      });
    },
  });
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars: { memberId: string; roleId: string }) =>
      toast.promise(cloudEnterpriseAdminApi.deleteRoleOnUser(vars), {
        loading: 'Removing role...',
        error: 'Unable to remove role',
        success: 'Role removed',
      }),
    onSettled() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.fleet.members,
      });
    },
  });
};

export const queryEntepriseOrgSettings = () =>
  queryOptions({
    queryKey: queryKeys.enterpriseAdmin.orgSettings,
    queryFn: () => cloudEnterpriseAdminApi.getOrgSettings(),
  });

export const useEnterpriseOrgSettings = () => {
  const auth = useAuth0();
  return useQuery({
    ...queryEntepriseOrgSettings(),
    enabled: auth.user && userHelpers.hasPermissionUpdateOrgSettings(auth.user),
  });
};

export const useEnableEnterpriseMigration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { domain?: string }) => {
      if (!vars.domain) {
        throw new Error('No domain provided.');
      }

      return cloudEnterpriseAdminApi.enableEnterpriseMigration({
        domain: vars.domain,
      });
    },
    onSettled() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseAdmin.orgSettings,
      });
    },
  });
};

export const useEnableEnterpriseDomainForwarding = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { domain?: string }) => {
      if (!vars.domain) {
        throw new Error('No domain provided.');
      }
      return cloudEnterpriseAdminApi.enableEnterpriseDomainForwarding({
        domain: vars.domain,
      });
    },
    onSettled() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseAdmin.orgSettings,
      });
    },
  });
};
