import { useEffect } from 'react';

import { Check, File, FileX, UploadSimple } from '@phosphor-icons/react';
import { Label, Typography } from '@remarkable/ark-web';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { queryKeys } from 'src/api/queries';
import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseSubmitConnection,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  Form,
  Input,
  NotificationBox,
  Sheet,
  Tooltip,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { CopyButton } from 'src/components/CopyButton';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

interface FormData {
  signInUrl: string;
  samlCertificate: FileList;
}

const routeApi = getRouteApi('/_auth/_layout/enterprise/enroll/setup-saml');

export function SetupSamlPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const navigate = routeApi.useNavigate();
  const submitSamlCertificate = useEnterpriseSubmitConnection();
  const queryClient = useQueryClient();

  const form = useForm<FormData>();

  useEffect(() => {
    if (!enrollmentStatus.data) return;

    form.reset({
      signInUrl: enrollmentStatus.data.signInUrl,
    });
  }, [enrollmentStatus.data]);

  const onSubmit = async (data: FormData) => {
    const certificate = samlCertificateFile?.[0]
      ? await samlCertificateFile[0].text()
      : enrollmentStatus.data?.base64Cert;

    if (!certificate) {
      // TODO: Should there be an error thrown here?
      return;
    }

    if (enrollmentStatus.data?.base64Cert && enrollmentStatus.data.signInUrl) {
      return navigate({ to: '../verify-saml' });
    }

    submitSamlCertificate.mutate(
      {
        signInUrl: data.signInUrl,
        certificate: certificate,
      },
      {
        async onSettled() {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.enterpriseEnrollment.status,
          });
        },
        onSuccess() {
          return navigate({ to: '../verify-saml' });
        },
      }
    );
  };

  const samlCertificateFile = form.watch('samlCertificate');
  const hasCertificate = !!(
    samlCertificateFile?.[0] ?? enrollmentStatus.data?.base64Cert
  );
  const fileName =
    samlCertificateFile?.[0]?.name ||
    (enrollmentStatus.data?.base64Cert ? 'Certificate' : 'No file');

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Set up SAML
            </Typography>

            <CancelSetupModal />
          </div>

          <Typography variant="body-md-regular">
            Copy and paste the Reply/
            <Tooltip
              title={
                <Typography
                  variant="interface-md-regular"
                  className="my-0 max-w-320 leading-5"
                >
                  Assertion Consumer Service URL - Used by your IdP to send a
                  SAML response back to the service provider (us) during
                  authentication.
                </Typography>
              }
              clickable
              asChild
            >
              <span className="cursor-help rounded underline decoration-dotted hover:bg-neutral-light-3">
                ACS
              </span>
            </Tooltip>{' '}
            URL and Entity ID into your identity provider (IdP). Then, from your
            IdP, copy and paste the Sign-in URL here and upload the certificate.
            You may need to configure additional mappings for successful
            authentication:
          </Typography>

          <div className="bg-neutral-light-3 px-16 py-8">
            <Typography variant="body-sm-regular">
              Property mappings:
            </Typography>

            <table className="w-full table-auto">
              <tbody>
                <tr>
                  <th className="text-left">
                    <Typography variant="body-sm-bold" className="my-0">
                      email:
                    </Typography>
                  </th>
                  <td>
                    <Typography variant="body-sm-regular" className="my-0">
                      The email of the user. Important that this the true email
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th className="text-left">
                    <Typography variant="body-sm-bold" className="my-0">
                      given_name:
                    </Typography>
                  </th>
                  <td>
                    <Typography variant="body-sm-regular" className="my-0">
                      The user&apos;s first name (will be displayed in the
                      overview)
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th className="text-left">
                    <Typography variant="body-sm-bold" className="my-0">
                      family_name:
                    </Typography>
                  </th>
                  <td>
                    <Typography variant="body-sm-regular" className="my-0">
                      The user&apos;s last name (will be displayed in the
                      overview)
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex items-end gap-24">
            <Input
              name="replyUrl"
              label="Reply / ACS URL"
              placeholder="Reply URL"
              disabled
              description="The endpoint in which your IdP will send its SAML response to."
              value={enrollmentStatus.data?.replyUrl ?? ''}
              inputClassName="cursor-text"
            />
            <CopyButton value={enrollmentStatus.data?.replyUrl ?? ''} />
          </div>

          <div className="flex items-end gap-24">
            <Input
              name="entityId"
              label="Entity ID"
              placeholder="Entity ID"
              disabled
              value={enrollmentStatus.data?.entityId}
              inputClassName="cursor-text"
            />
            <CopyButton value={enrollmentStatus.data?.entityId} />
          </div>

          <Form {...form} onSubmit={onSubmit} className="flex flex-col gap-24">
            <input type="hidden" value="something" />
            <Input
              name="signInUrl"
              label="Sign-in URL"
              type="url"
              description="Provided by your IdP when setting up new integrations"
              placeholder="https://example.com/saml/login?SAMLRequest=..."
              disabled={!!enrollmentStatus.data?.signInUrl}
              required
            />

            <div>
              <Label htmlFor="samlCertificate">SAML certificate</Label>
              <Typography
                variant="interface-xs-regular"
                className="text-neutral-dark-1"
              >
                SAMLP server public key encoded in PEM or CER format. Provided
                by your IdP when setting up new integrations.
              </Typography>

              <Tooltip
                asChild
                title={'Certificate received'}
                enabled={!!enrollmentStatus.data?.base64Cert}
              >
                <label
                  htmlFor="samlCertificate"
                  className={clsx(
                    'group flex h-48 w-full items-end justify-between gap-24',
                    enrollmentStatus.data?.base64Cert
                      ? 'cursor-help'
                      : 'cursor-pointer'
                  )}
                >
                  <div className="flex h-48 w-full items-center justify-between gap-8 rounded border border-dashed px-16 focus-within:outline focus-within:outline-pen-blue">
                    <div className="flex items-center gap-8">
                      {hasCertificate ? (
                        <File
                          className="inline shrink-0 text-neutral-dark-1"
                          size={24}
                          weight="light"
                        />
                      ) : (
                        <FileX
                          className="inline shrink-0 text-neutral-dark-1"
                          size={24}
                          weight="light"
                        />
                      )}
                      <Typography
                        variant="interface-md-regular"
                        className="truncate"
                      >
                        {fileName}
                      </Typography>
                      <Input
                        className="w-0 opacity-0"
                        name="samlCertificate"
                        type="file"
                        required={!enrollmentStatus.data?.base64Cert}
                        disabled={!!enrollmentStatus.data?.base64Cert}
                      />
                    </div>

                    {enrollmentStatus.data?.base64Cert && (
                      <Check size={24} className="text-pen-blue" />
                    )}
                  </div>

                  {!enrollmentStatus.data?.base64Cert && (
                    <div
                      role="button"
                      tabIndex={-1}
                      className="ark-button ark-button-large ark-button--tertiary group-focus-within:bg-pen-blue-light"
                    >
                      <span>Upload</span>
                      <UploadSimple size={24} />
                    </div>
                  )}
                </label>
              </Tooltip>
            </div>
            <div className="flex justify-center gap-24">
              <Button
                as="a"
                variant="secondary"
                to={URLS.ENTERPRISE_VERIFY_DOMAIN}
              >
                Previous
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={submitSamlCertificate.isPending}
                disabled={submitSamlCertificate.isPending}
              >
                Continue
              </Button>
            </div>
          </Form>

          <NotificationBox error={submitSamlCertificate.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
