import { Typography } from '@remarkable/ark-web';
import { Desktop as DesktopIcon, DownloadSimple } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useDevices, useHasEverPairedDevice } from 'src/api/queries';
import {
  Button,
  CardContent,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { URLS } from 'src/utils/urls/urls';

import { filterActiveDesktopAppClients, getDesktopLink } from '../utils/utils';
import { DeviceTable } from './DeviceTable';
import { OneTimeCode } from './OneTimeCode';

interface DesktopProps {
  showOtp?: boolean;
  appMode?: boolean;
}

export const Desktop = ({ showOtp, appMode }: DesktopProps) => {
  const activeDesktopClients = useDevices({
    select: filterActiveDesktopAppClients,
  });

  const hasEverPairedDevice = useHasEverPairedDevice();

  if (activeDesktopClients.isPending || hasEverPairedDevice.isPending) {
    return <Spinner />;
  }

  if (activeDesktopClients.isError || hasEverPairedDevice.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <div className="flex flex-col gap-16 lm:gap-24">
      <Sheet>
        {!showOtp ? (
          <>
            <CardContent className="my-12 flex flex-row items-center justify-between lm:mt-0">
              <div className="flex flex-row items-center">
                <DesktopIcon size={32} className="mr-16" />
                <Typography variant="heading-subtle-2xl" as="h2">
                  Desktop app
                </Typography>
              </div>
              <Button
                className="hidden md:flex"
                as="a"
                variant={
                  activeDesktopClients.data.length > 0 ? 'tertiary' : 'primary'
                }
                size="medium"
                to={getDesktopLink()}
                analytics={{
                  action: 'download desktop app',
                  location: ComponentLocations.DEVICE.DESKTOP,
                  text: 'Download',
                }}
              >
                <DownloadSimple size={24} />
                <span>Download</span>
              </Button>
            </CardContent>

            <CardContent borderTop className="flex flex-col gap-24 text-left">
              <p>
                Use the reMarkable desktop app to view, import, organize, and
                present your content with Screen Share.
              </p>
              <Button
                className="md:hidden"
                as="a"
                size="medium"
                variant={
                  activeDesktopClients.data.length > 0 ? 'tertiary' : 'primary'
                }
                to={getDesktopLink()}
                data-cy="download-desktop-app-button"
              >
                <DownloadSimple size={24} />
                <span>Download</span>
              </Button>
            </CardContent>
          </>
        ) : (
          <CardContent>
            <OneTimeCode
              hideBack={appMode}
              successRedirectUrl={appMode ? undefined : URLS.DEVICE_DESKTOP}
              description={
                appMode
                  ? 'Navigate back and enter this one-time code in the app login window to pair it with your reMarkable and sync your notes.'
                  : 'Enter this one-time code in the open app window on your desktop to pair it with your reMarkable and sync your notes.'
              }
              allowPairing={hasEverPairedDevice.data}
              deviceTypeString="desktop app"
              componentLocation={ComponentLocations.DEVICE.DESKTOP}
            />
          </CardContent>
        )}
      </Sheet>
      {!showOtp && <DeviceTable deviceType="desktop-app" />}
    </div>
  );
};
