import { createFileRoute, redirect } from '@tanstack/react-router';

import { queryEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { enterpriseHelpers } from 'src/utils/enterpriseHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/enroll/')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }

    const enrollmentStatus = await context.queryClient.ensureQueryData(
      queryEnterpriseEnrollmentStatus()
    );

    const step = enterpriseHelpers.getEnrollmentStep(
      enrollmentStatus,
      auth.user
    );

    const stepUrl = enterpriseHelpers.getEnrollmentStepUrl(step);

    throw redirect({
      to: stepUrl,
      replace: true,
    });
  },
});
