export interface CheckoutFormState {
  country: string;
  state?: string;
  firstName?: string;
  lastName?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  postalCode?: string;
  city?: string;
  companyName?: string;
  VAT?: string;
  hasAcceptedTerms: boolean;
}

export enum CheckoutErrorType {
  STRIPE_ERROR = 'STRIPE_ERROR',
  STRIPE_VALIDATION_ERROR = 'STRIPE_VALIDATION_ERROR',
  FETCH_CHECKOUT_ERROR = 'FETCH_CHECKOUT_ERROR',
}

export interface CheckoutError {
  type: CheckoutErrorType;
  message: string | JSX.Element;
  title: string;
}
