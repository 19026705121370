import { Typography } from '@remarkable/ark-web';

import { CountryCode } from 'src/api/endpoints/ipstackApi.types';
import { CheckoutTypeParam } from 'src/api/endpoints/storeApi.types';
import {
  CardContent,
  Feedback,
  MyRmExperienceCardRow,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { StatusTag } from 'src/components/StatusTag';
import { getCountryDataForCountryCodeWithFallback } from 'src/utils/countryDetector';
import { formatDate } from 'src/utils/formatDate';
import { formatAmount } from 'src/utils/productUtils';
import { URLS } from 'src/utils/urls/urls';

import { ConnectSummary } from '../components/ConnectSummary';
import { PaymentInformation } from '../components/PaymentInformation';
import { StartLearning } from '../components/StartLearning';
import { useCheckoutConfirmation } from '../useCheckoutConfirmation';

export const ConnectConfirmationPage = () => {
  const { isPending, isError, error, paymentConfirm, subscription } =
    useCheckoutConfirmation({
      checkoutType: CheckoutTypeParam.STORE,
    });

  if (isPending) {
    return <Spinner />;
  }
  if (isError || !paymentConfirm || !subscription) {
    return <SomethingWentWrong error={error} />;
  }

  const priceWithTax = formatAmount(
    paymentConfirm.data.cartPrice.total,
    paymentConfirm.data.currency
  );
  const nextBillingDate = new Date(paymentConfirm.data.billingDate);

  return (
    <AppPage.Container>
      <AppPage.Content className="max-w-lxl">
        <div className="flex w-full flex-col items-center justify-center gap-64 bg-neutral-light-2 pb-128 pt-64">
          <div className="flex max-w-2xl flex-col items-center px-16">
            <div className="mb-64 text-center">
              <h1
                data-cy="welcome-message"
                className="m-0 mb-16 font-serif text-68"
              >
                Welcome to Connect
              </h1>
              <p className="m-0 font-sans text-18">
                You&apos;ve successfully activated your Connect subscription.
                Now you can take your notes even further.{' '}
                <LinkExternal to={URLS.RM_COM_STORE_CONNECT} inline>
                  Read more
                </LinkExternal>
              </p>
            </div>
            <Sheet data-cy="confirmation-card" className="w-full">
              <div>
                <CardContent>
                  <div className="flex flex-row items-center justify-between pb-24">
                    <h2
                      className="body-2xl-regular font-semibold"
                      data-cy="confirmation-card-heading-title"
                    >
                      Connect
                    </h2>
                    <StatusTag status="Active" />
                  </div>
                </CardContent>
                <CardContent borderTop>
                  <ConnectSummary
                    data={paymentConfirm}
                    country={getCountryDataForCountryCodeWithFallback(
                      paymentConfirm.data.billingAddress.country as CountryCode
                    )}
                    state={paymentConfirm.data.billingAddress.county}
                  />
                </CardContent>

                <CardContent borderTop>
                  <div className="flex flex-row items-center justify-between">
                    <p className="m-0 text-16">Next billing:</p>
                    <p
                      data-cy="checkout-summary-billing-start-date"
                      className="m-0 text-16"
                    >
                      {formatDate(nextBillingDate.toString())}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="m-0 whitespace-nowrap text-16">Order id:</p>
                    <p
                      className="m-0 text-right text-16"
                      data-cy="checkout-order-id"
                    >
                      {paymentConfirm.data.orderId}
                    </p>
                  </div>

                  <PaymentInformation subscription={subscription} />
                </CardContent>
              </div>
              <CardContent borderTop>
                <div className="flex flex-row items-center justify-between">
                  <p className="m-0 text-16">
                    You&apos;ll be billed {priceWithTax} until you cancel your
                    subscription. View your current subscription or update your
                    payment details at the{' '}
                    <Link2 to="/subscriptions" inline>
                      subscription page
                    </Link2>
                    . You&apos;ll receive your order confirmation email shortly.
                  </p>
                </div>
              </CardContent>
            </Sheet>
          </div>

          <StartLearning />

          <div className="max-w-screen-lg px-16 lm:px-0">
            <Typography
              as="h2"
              variant="responsive-heading-lg"
              className="mb-32  text-center"
            >
              Explore the reMarkable universe
            </Typography>
            <MyRmExperienceCardRow />
          </div>

          <Feedback
            title="Did you find what you were looking for?"
            feedbackTargetId="store-connect-confirmation"
          />
        </div>
      </AppPage.Content>
    </AppPage.Container>
  );
};
