import { ReadOnRM } from '@remarkable/ark-icons';
import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { DownloadSimple, GoogleChromeLogo } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useDevices, useHasEverPairedDevice } from 'src/api/queries';
import {
  Button,
  CardContent,
  FAQ,
  FAQLine,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

import { CHROME_EXTENSION_URL, OFFICE_EXTENSION_URL } from '../utils/constants';
import { filterActiveBrowserExtensionClients } from '../utils/utils';
import { DeviceTable } from './DeviceTable';
import { OneTimeCode } from './OneTimeCode';

const routeApi = getRouteApi('/_auth/_layout/device/browser');

export const Browser = () => {
  const { showOtp } = routeApi.useSearch();
  const activeBrowserClients = useDevices({
    select: filterActiveBrowserExtensionClients,
  });
  const hasEverPairedDevice = useHasEverPairedDevice();

  if (activeBrowserClients.isPending || hasEverPairedDevice.isPending) {
    return <Spinner />;
  }

  if (activeBrowserClients.isError || hasEverPairedDevice.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <div className="flex flex-col gap-16 lm:gap-24">
      <Sheet>
        {!showOtp ? (
          <>
            <CardContent className="my-12 flex flex-row items-center justify-between lm:mt-0">
              <div className="flex flex-row items-center">
                <GoogleChromeLogo className="mr-16" size={32} />
                <Typography variant="heading-subtle-2xl" as="h2">
                  Google Chrome
                </Typography>
              </div>
              <Button
                as="a"
                to={CHROME_EXTENSION_URL}
                className="hidden md:flex"
                size="medium"
                variant={
                  activeBrowserClients.data.length > 0 ? 'tertiary' : 'primary'
                }
                analytics={{
                  action: 'download chrome extension',
                  location: ComponentLocations.DEVICE.READ_ON_REMARKABLE,
                  text: 'Get extension',
                }}
              >
                <DownloadSimple size={24} />
                <span>Get extension</span>
              </Button>
            </CardContent>
            <CardContent borderTop className="flex flex-col gap-24 text-left">
              <p>
                Convert web pages to PDF or EPUB and send to your device with
                one click
              </p>
              <Button
                size="medium"
                data-cy="download-chrome-extension-button"
                as="a"
                to={CHROME_EXTENSION_URL}
                className="md:hidden"
                variant={
                  filterActiveBrowserExtensionClients(activeBrowserClients.data)
                    .length > 0
                    ? 'tertiary'
                    : 'primary'
                }
              >
                <DownloadSimple size={24} />
                <span>Get extension</span>
              </Button>
            </CardContent>
          </>
        ) : (
          <CardContent>
            <OneTimeCode
              successRedirectUrl={URLS.DEVICE_BROWSER}
              description="Enter this one-time code in your Chrome browser window to pair it with your device and start saving web articles to read."
              allowPairing={hasEverPairedDevice.data}
              deviceTypeString="browser extension"
              componentLocation={ComponentLocations.DEVICE.READ_ON_REMARKABLE}
            />
          </CardContent>
        )}
      </Sheet>

      {!showOtp && (
        <>
          <Sheet>
            <CardContent className="my-12 flex flex-row items-center justify-between lm:mt-0">
              <div className="flex flex-row items-center">
                <ReadOnRM className="mr-16" size={32} />
                <Typography variant="heading-subtle-2xl" as="h2">
                  Microsoft Office
                </Typography>
              </div>
              <Button
                as="a"
                size="medium"
                className="hidden md:flex"
                to={OFFICE_EXTENSION_URL}
                variant={
                  activeBrowserClients.data.length > 0 ? 'tertiary' : 'primary'
                }
              >
                <DownloadSimple size={24} />
                <span>Get add-in</span>
              </Button>
            </CardContent>
            <CardContent borderTop className="flex flex-col gap-24 text-left">
              <p>
                Send Word documents or PowerPoint slides direct to your device
                as PDFs
              </p>
              <Button
                data-cy="download-office-add-in-button"
                as="a"
                size="medium"
                className="md:hidden"
                to={OFFICE_EXTENSION_URL}
                variant={
                  activeBrowserClients.data.length > 0 ? 'tertiary' : 'primary'
                }
              >
                <DownloadSimple size={24} />
                <span>Get add-in</span>
              </Button>
            </CardContent>
          </Sheet>

          <DeviceTable deviceType="read-on-remarkable" />

          <FAQ>
            <FAQLine
              text="How do I send web articles to my reMarkable?"
              target={SUPPORT_URL.ARTICLES.ROR_GOOGLE_CHROME_EXTENSION}
            />
            <FAQLine
              text="How do I send Word and PowerPoint documents to my reMarkable?"
              target={SUPPORT_URL.ARTICLES.ROR_WORD_AND_POWERPOINT}
            />
            <FAQLine
              text="Where can I get help using Read on reMarkable?"
              target={SUPPORT_URL.ARTICLES.ROR_WORD_AND_POWERPOINT}
            />
            <FAQLine
              text="Can I read Kindle ebooks on reMarkable 2?"
              target={SUPPORT_URL.ARTICLES.KINDLE_ON_REMARKABLE}
            />
            <FAQLine
              text="How do I get ebooks on my device?"
              target={SUPPORT_URL.ARTICLES.EBOOKS_ON_REMARKABLE}
            />
            <FAQLine
              text="Which file types are supported on reMarkable?"
              target={SUPPORT_URL.ARTICLES.FILETYPES_REMARKABLE}
            />
            <FAQLine
              text="How do I connect to the reMarkable cloud?"
              target={SUPPORT_URL.ARTICLES.PAIR_REMARKABLE_WITH_CLOUD}
            />
          </FAQ>
        </>
      )}
    </div>
  );
};
